import { useContext, useEffect, useMemo, useState } from 'react';
import { RouteChildrenProps, useHistory } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import CopyToClipboard from 'react-copy-to-clipboard';
import { strings } from '../utils/Localization/LocalizationStrings';
import Vector from '../assets/img/Vector 87.svg';
import SearchBar from '../components/SearchBar';
import SearchSideBar from '../components/SearchSideBar';

import UserinfoContext from '../context/UserinfoContext';
import { getWatchList } from '../modules/API/WatchAPI';
import WatchList from '../components/WatchList';
import TextTap from '../containers/TextTap';
import { getUserWallet } from '../modules/API/WalletAPI';
import { UserWallet, WatchFaceNFT } from '../types';
import defaultProfile from '../assets/img/default-profile.png';
import { ellipsisBetween, parseThumbnail } from '../utils/stringUtils';
import UserActivity from '../containers/UserActivity';
import { getUserInfo } from '../modules/API/UserAPI';
import {
  CategoryCheckBoxDefaultInputs,
  OsCheckBoxDefaultInputs,
  useWatchfaceFilter,
} from '../hooks/useWatchfaceFilter';
import watch from '../assets/img/Group 2517.svg';
import useLoading from '../customHooks/useLoading';
import Modal from '../components/Modal';
import WalletConnectContext from '../context/WalletConnectContext';

type FilterFunction = (item: WatchFaceNFT) => boolean;

export default function UserPage({ match }: RouteChildrenProps<{ userid: string }>) {
  const [userInfo, setUserInfo] = useState<UserWallet | null>(null);
  const menus: { key: string; filterFunction?: FilterFunction; noResultMessage?: string }[] = [
    {
      key: 'Created',
      filterFunction: ({ status }) => status === 'created',
      noResultMessage: strings.SearchNoRsCreate,
    },
    {
      key: 'On Sale',
      filterFunction: ({ status }) => status === 'sale',
      noResultMessage: strings.SearchNoRsSale,
    },
    {
      key: 'Owned',
      filterFunction: ({ owner }) => owner === userInfo?.walletAddress,
      noResultMessage: strings.SearchNoRsOwned,
    },
    {
      key: 'Activity',
    },
    {
      key: 'Hidden',
      filterFunction: ({ status }) => status === 'hidden',
      noResultMessage: strings.SearchNoRsHidden,
    },
  ];
  const [selectedMenu, setSelectedMenu] = useState<(typeof menus)[number]['key']>('Created');

  const [showSideBar, setShowSideBar] = useState(window.matchMedia('(min-width: 768px)').matches);
  const navigate = useHistory();
  const [watchFaces, setWatchFaces] = useState<WatchFaceNFT[] | null>(null);
  const { user, setUser } = useContext(UserinfoContext);
  const { requestSigning, connectedWallet } = useContext(WalletConnectContext);
  const [Loading, toggle] = useLoading();

  const isOwner = useMemo(() => user != null && user?._id === match?.params.userid, [user]);

  const currentAddress = useMemo(
    () => userInfo?.walletAddress && ellipsisBetween(userInfo.walletAddress, 4),
    [userInfo?.walletAddress]
  );

  const { states, getSetter, filterWatchFace, sortWatchFace } = useWatchfaceFilter<WatchFaceNFT>();

  useEffect(() => {
    (async () => {
      if (match?.params.userid == null) return;
      const userWallet = await getUserWallet(match?.params.userid);

      if (userWallet != null) {
        setUserInfo(userWallet);
        return;
      }

      const selectedUser = await getUserInfo(match?.params.userid);

      if (selectedUser == null) {
        navigate.push('/404');
      }

      setUserInfo({
        user: selectedUser,
        walletAddress: null,
      });
    })();
  }, [match?.params.userid]);

  useEffect(() => {
    if (userInfo == null) return;
    (async () => {
      try {
        toggle(true);
        setWatchFaces(
          await getWatchList({
            owner: userInfo.walletAddress ?? undefined,
            author: userInfo.user._id,
            hidden: isOwner,
          })
        );
      } catch (err) {
        setWatchFaces([]);
      } finally {
        toggle(false);
      }
    })();
  }, [userInfo, isOwner]);

  const filteredWatchFaces = useMemo(
    () => (watchFaces == null ? null : sortWatchFace(filterWatchFace(watchFaces))),
    [watchFaces, filterWatchFace, sortWatchFace]
  );

  const filteredLengths = useMemo(
    () =>
      menus.map(({ key, filterFunction }) => ({
        key,
        length: filterFunction && filteredWatchFaces?.filter(filterFunction).length,
      })),
    [filteredWatchFaces]
  );

  const currentFilter = useMemo(
    () => menus.find(({ key }) => key === selectedMenu),
    [selectedMenu]
  );

  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const openModal = (message: string) => {
    setModalMessage(message);
    setModalOpen(true);
  };

  return (
    <div className=" flex flex-col md:mt-[40px] md:h-full max-md:px-2  max-md:bg-white w-full ">
      <img
        src={parseThumbnail(userInfo?.user.thumbnail) || defaultProfile}
        onError={({ currentTarget }) => {
          // eslint-disable-next-line no-param-reassign
          currentTarget.src = defaultProfile;
        }}
        alt="profileImage"
        className="w-24 h-24 rounded-full box-border m-auto"
      />
      <p className="mx-auto flex justify-center items-center">
        <span className="font-bold inline-block text-4xl my-3">{userInfo?.user.nickname}</span>
        {isOwner && (
          <button
            className="ml-3 inline-block bg-[#B7C0C6] rounded-2xl text-white py-0.5 px-2"
            onClick={() => {
              localStorage.removeItem('X-Instant-Token');
              setUser(null);
              if (window.Appbridge?.onSignOut != null) window.Appbridge.onSignOut();
              navigate.push(`/${strings.getLanguage()}/signin`);
            }}
          >
            Logout
          </button>
        )}
      </p>
      {currentAddress && (
        <div className=" block text-center font-light text-base">
          <CopyToClipboard text={userInfo?.walletAddress ?? ''}>
            <button className="cursor-pointer">
              <img src={Vector} alt="wallet Link" className="inline" />

              <span className="ml-1">{currentAddress}</span>
            </button>
          </CopyToClipboard>
        </div>
      )}
      {isOwner && (
        <button
          className="bg-myColor text-white font-bold p-2 rounded my-10 mx-auto"
          onClick={async () => {
            if (connectedWallet == null) {
              openModal(strings.ConnectWallet_WalletNotConnected);
              return;
            }
            await requestSigning('sign for create');
            navigate.push(`/${strings.getLanguage()}/facefacelist`);
          }}
        >
          + Create
        </button>
      )}

      <div className="h-12 w-full m-auto ">
        <TextTap
          values={filteredLengths}
          selectedMenu={selectedMenu}
          setSelectedMenu={setSelectedMenu}
        />
      </div>
      <section className="w-full flex flex-col max-md:px-4 max-md:bg-white">
        <div className="flex flex-wrap md:gap-x-5 m-auto w-full">
          <div className="w-full mt-4">
            <SearchBar
              showSideBarHandler={() =>
                setShowSideBar((prev) => selectedMenu !== 'Activity' && !prev)
              }
              placeholder={strings.InputSearch}
              setWord={getSetter('searchKeyword')}
              word={states.searchKeyword}
              selectedOption={states.sort}
              setSelectOption={getSetter('sort')}
            />
          </div>
          <div className="md:w-[224px] md:h-[764px]">
            {selectedMenu !== 'Activity' && showSideBar && (
              <SearchSideBar
                priceProps={{
                  max: states.max,
                  min: states.min,
                  priceHandler: ({ min, max }) => {
                    getSetter('min')(min);
                    getSetter('max')(max);
                  },
                }}
                categoryProps={{
                  selectedCategories: states.selectedCategories,
                  categoryList: CategoryCheckBoxDefaultInputs,
                  categoryHandler: (checked, id) =>
                    getSetter('selectedCategories')((currentCategories) => {
                      return checked
                        ? [...currentCategories, id]
                        : currentCategories.filter((el) => el !== id);
                    }),
                }}
                osProps={{
                  selectedOs: states.selectedOs,
                  osList: OsCheckBoxDefaultInputs,
                  osHandler: (checked, id) =>
                    getSetter('selectedOs')((currentOs) =>
                      checked ? [...currentOs, id] : currentOs.filter((el) => el !== id)
                    ),
                }}
                closeOnFullscreen={() => setShowSideBar(false)}
              />
            )}
          </div>
          {currentFilter?.filterFunction && (
            <div className="flex-[1_1_60%]">
              {filteredWatchFaces != null ? (
                <WatchList
                  watchItems={filteredWatchFaces.filter(currentFilter.filterFunction)}
                  linkFunction={(id) => `/${strings.getLanguage()}/itemdetails/${id}`}
                  noResultMessage={currentFilter.noResultMessage ?? ''}
                />
              ) : (
                <div className="flex flex-col w-full mt-[176px]">
                  <img src={watch} alt="시계" className="m-auto " />
                  <p className="m-auto text-bold">{strings.SearchLoading}</p>
                </div>
              )}
            </div>
          )}
          {selectedMenu === 'Activity' && userInfo && (
            <UserActivity walletAddress={userInfo.walletAddress} />
          )}
        </div>
      </section>
      <Loading />
      {modalOpen && (
        <Modal
          close={() => setModalOpen(false)}
          outsideClick={() => setModalOpen(false)}
          header={modalMessage}
        />
      )}
    </div>
  );
}
