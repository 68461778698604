import { Chain, EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum';
import { configureChains, createClient, goerli, mainnet } from 'wagmi';
import configs from '../constants/configs';

const chains: Chain[] = (configs.REACT_APP_WALLET_CONNECT_CHAINS?.split(',') ?? [])
  .map((chain) => {
    switch (chain) {
      case 'goerli':
        return goerli;
      case 'mainnet':
        return mainnet;
      case 'privateNetwork':
        return {
          id: 2757,
          name: 'ETH Private Network',
          network: 'eth-private-network',
          nativeCurrency: {
            decimals: 18,
            name: 'ETH',
            symbol: 'ETH',
          },
          rpcUrls: {
            public: { http: [configs.REACT_APP_WEB3_DEFAULT_PROVIDER] },
            default: { http: [configs.REACT_APP_WEB3_DEFAULT_PROVIDER] },
          },
          blockExplorers: {
            etherscan: { name: 'SnowTrace', url: 'https://snowtrace.io' },
            default: { name: 'SnowTrace', url: 'https://snowtrace.io' },
          },
          // contracts: {
          //   multicall3: {
          //     address: '0xca11bde05977b3631167028862be2a173976ca11',
          //     blockCreated: 11_907_934,
          //   },
          // },
        };
      default:
        return undefined;
    }
  })
  .filter((chain) => chain !== undefined) as Chain[];

export const projectId: string = configs.REACT_APP_WALLET_CONNECT_PROJECT_ID ?? '';

const { provider } = configureChains(chains, [w3mProvider({ projectId })]);

export const wagmiClient = createClient({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, version: 1, chains }),
  provider,
});
export const ethereumClient = new EthereumClient(wagmiClient, chains);
