import { useContext, useEffect, useMemo, useState } from 'react';
import { strings } from '../utils/Localization/LocalizationStrings';
import SearchBar from '../components/SearchBar';

import { listPublishWatch } from '../modules/API/WatchAPI';
import WatchList from '../components/WatchList';
import SearchSideBar from '../components/SearchSideBar';
import { WatchFaceItem } from '../types';
import UserinfoContext from '../context/UserinfoContext';
import {
  CategoryCheckBoxDefaultInputs,
  OsCheckBoxDefaultInputs,
  useWatchfaceFilter,
} from '../hooks/useWatchfaceFilter';
import useLoading from '../customHooks/useLoading';
import watch from '../assets/img/Group 2517.svg';

function FaceFaceList() {
  const [showSideBar, setShowSideBar] = useState(window.matchMedia('(min-width : 768px)').matches);

  const { states, getSetter, filterWatchFace, sortWatchFace } = useWatchfaceFilter<WatchFaceItem>();

  const [watchListData, setwatchListData] = useState<WatchFaceItem[] | null>(null);

  const { user } = useContext(UserinfoContext);

  const [Loading, toggle] = useLoading();

  const filteredWatchFaces = useMemo(
    () => (watchListData == null ? null : sortWatchFace(filterWatchFace(watchListData))),
    [watchListData, filterWatchFace, sortWatchFace]
  );

  useEffect(() => {
    (async () => {
      try {
        toggle(true);
        if (user == null) throw new Error('no user');
        setwatchListData(await listPublishWatch());
      } catch (e) {
        setwatchListData([]);
      } finally {
        toggle(false);
      }
    })();
  }, [user]);

  return (
    <section className="w-full flex flex-col max-md:px-4 max-md:bg-white">
      <div className="flex flex-wrap md:gap-x-5 m-auto">
        <div className="w-full mt-4">
          <SearchBar
            showSideBarHandler={() => setShowSideBar((prev) => !prev)}
            placeholder={strings.InputSearch}
            setWord={getSetter('searchKeyword')}
            word={states.searchKeyword}
            selectedOption={states.sort}
            setSelectOption={getSetter('sort')}
          />
        </div>
        <div className="md:w-[224px] md:h-[703px]">
          {showSideBar && (
            <SearchSideBar
              categoryProps={{
                selectedCategories: states.selectedCategories,
                categoryList: CategoryCheckBoxDefaultInputs,
                categoryHandler: (checked, id) =>
                  getSetter('selectedCategories')((currentCategories) =>
                    checked
                      ? [...currentCategories, id]
                      : currentCategories.filter((el) => el !== id)
                  ),
              }}
              osProps={{
                selectedOs: states.selectedOs,
                osList: OsCheckBoxDefaultInputs,
                osHandler: (checked, id) =>
                  getSetter('selectedOs')((currentOs) =>
                    checked ? [...currentOs, id] : currentOs.filter((el) => el !== id)
                  ),
              }}
              closeOnFullscreen={() => setShowSideBar(false)}
            />
          )}
        </div>
        <div className="flex-[1_1_50%]">
          {filteredWatchFaces != null ? (
            <WatchList
              watchItems={filteredWatchFaces || []}
              linkFunction={(id) => `/${strings.getLanguage()}/mintingwrite/${id}`}
              noResultMessage={strings.SearchNoRsCreate}
            />
          ) : (
            <div className="flex flex-col w-full mt-[176px]">
              <img src={watch} alt="시계" className="m-auto " />
              <p className="m-auto text-bold">{strings.SearchLoading}</p>
            </div>
          )}
        </div>
      </div>
      <Loading />
    </section>
  );
}

export default FaceFaceList;
