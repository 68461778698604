import { useHistory } from 'react-router-dom';
import { useContext, useEffect, useMemo, useState } from 'react';
import { strings } from '../utils/Localization/LocalizationStrings';
import BaseBanner from '../components/BaseBanner';
import WatchFaceList from '../components/WatchFaceItem';
import Footer from '../components/Footer';
import effectIcon from '../assets/img/effectIcon.svg';
import designLinkImg from '../assets/img/aboutDesignImg.png';
import createLinkImg from '../assets/img/aboutCreateImg.png';
import applyLinkImg from '../assets/img/aboutApplyImg.png';
import sampleHomeBanner from '../assets/img/sampleHomeBanner.png';
import { Banner, WatchFaceNFT } from '../types';
import { getWatchList } from '../modules/API/WatchAPI';
import { getBanner } from '../modules/API/BannerAPI';
import { getWalletOwner } from '../modules/API/WalletAPI';
import { parseThumbnail } from '../utils/stringUtils';
import Modal from '../components/Modal';
import WalletConnectContext from '../context/WalletConnectContext';

const aboutTimeFlickLinks = [
  {
    src: designLinkImg,
    alt: 'design page link background img',
    href: 'https://studio-dev.timeflik.com/',
    content: 'Degin Your Own',
  },
  {
    src: createLinkImg,
    alt: 'create page link background img',
    href: `/${strings.getLanguage()}/facefacelist`,
    content: 'Creating & Buying',
  },
  {
    src: applyLinkImg,
    alt: 'apply page link background img',
    href: `/${strings.getLanguage()}/mypage`,
    content: 'Apply to Your Watch',
  },
];

function Home() {
  const navigate = useHistory();
  const [watchListData, setWatchListData] = useState<(WatchFaceNFT & { ownerName: string })[]>([]);
  const [topBanners, setTopBanners] = useState<Banner[]>([]);
  const [centerBanner, setCenterBanner] = useState<Banner | null>();
  const [bottomBanner, setBottomBanner] = useState<Banner | null>();
  const [currentBannerIndex, setCurrentBannerIndex] = useState(0);
  const { requestSigning, connectedWallet } = useContext(WalletConnectContext);

  const bannerTranslation = useMemo(
    () => ({ transform: `translateX(${-(100 * currentBannerIndex)}%)` }),
    [currentBannerIndex]
  );

  useEffect(() => {
    (async () => {
      setTopBanners(
        await getBanner({
          position: 'top',
        })
      );
      setCenterBanner(
        (
          await getBanner({
            position: 'center',
          })
        )[0] || null
      );
      setBottomBanner(
        (
          await getBanner({
            position: 'bottom',
          })
        )[0] || null
      );

      const watchList = await getWatchList();

      setWatchListData(
        await Promise.all(
          watchList.map(async ({ owner, author, thumbnail, ...rests }) => {
            const ownerInfo = owner != null ? await getWalletOwner(owner) : null;
            return {
              owner,
              author,
              thumbnail: parseThumbnail(thumbnail) || '',
              ...rests,
              ownerName: ownerInfo?.user.nickname ?? author.nickname,
            };
          })
        )
      );
    })();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentBannerIndex((prevIndex) => (prevIndex + 1) % topBanners.length);
    }, 7000);
    return () => clearInterval(interval);
  }, [topBanners.length]);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const openModal = (message: string) => {
    setModalMessage(message);
    setModalOpen(true);
  };

  return (
    <section className="w-full ">
      <section className="relative w-full h-[220px] sm:h-[240px] my-[40px]">
        <img
          src={sampleHomeBanner}
          alt="HomeBanner SampleImage"
          className="rounded-[8px] max-md:h-[220px]"
        />
        <div className="absolute top-1/2 sm:left-1/2 sm:translate-x-[-50%] translate-y-[-50%] text-center">
          <p className="text-white text-[20px] max-md:px-2 sm:text-[25px] font-semibold">
            {strings.Home_WatchfaceMarketplaceExplain}
          </p>
          <button
            className="w-[140px] py-[10px] bg-white mt-[20px] text-[#3b3066] font-semibold rounded-[8px]"
            onClick={async () => {
              if (connectedWallet == null) {
                openModal(strings.ConnectWallet_WalletNotConnected);
                return;
              }
              await requestSigning('sign for create');
              navigate.push(`/${strings.getLanguage()}/FaceFaceList`);
            }}
          >
            {strings.CREATE_ITEM}
          </button>
        </div>
      </section>
      <div className="overflow-x-hidden relative">
        <button
          type="button"
          onClick={() => setCurrentBannerIndex((prevIndex) => (prevIndex || topBanners.length) - 1)}
          className="inline-block absolute left-0 h-full w-7 z-10 opacity-0 hover:opacity-100 bg-[rgba(0,0,0,0.3)] text-white"
        >
          &lt;
        </button>
        <button
          type="button"
          onClick={() => setCurrentBannerIndex((prevIndex) => (prevIndex + 1) % topBanners.length)}
          className="inline-block absolute right-0 h-full w-7 z-10 opacity-0 hover:opacity-100 bg-[rgba(0,0,0,0.3)] text-white"
        >
          &gt;
        </button>
        <ul className="flex w-full" style={bannerTranslation}>
          {topBanners.map((banner) => (
            <li className="w-full flex-none" key={banner._id}>
              <BaseBanner
                banner={banner}
                titleClass="text-[#462B7D]"
                subTitleClass="text-[#462B7D]"
              />
            </li>
          ))}
        </ul>
      </div>
      <section className="mb-[59.5px] max-md:m-[13px]">
        <h2 className="text-[24px] font-semibold mt-[60px]">{strings.New_Arrivals}</h2>
        <ul className="flex md:flex-wrap md:gap-[5.5px] mt-[20px] max-md:overflow-scroll">
          {watchListData.map(({ _id, title, ownerName, price, thumbnail }) => {
            return (
              <WatchFaceList
                key={_id}
                watchOwner={ownerName}
                watchName={title}
                watchPrice={price}
                watchFaceImg={thumbnail}
                url={`/${strings.getLanguage()}/purchase/${_id}`}
                mobileDirection="row"
              />
            );
          })}
        </ul>
      </section>
      {centerBanner && (
        <BaseBanner
          banner={centerBanner}
          titleClass="text-[#462B7D]"
          subTitleClass="text-[#462B7D]"
        />
      )}
      <section className="mb-[59.5px] max-md:m-[13px]">
        <h2 className="text-[24px] font-semibold mt-[60px] ">{strings.Trending}</h2>
        <ul className="flex flex-wrap md:gap-[5.5px] mt-[20px]">
          {watchListData.map(({ _id, title, ownerName, price, thumbnail }) => {
            return (
              <WatchFaceList
                key={_id}
                watchOwner={ownerName}
                watchName={title}
                watchPrice={price}
                watchFaceImg={thumbnail}
                url={`/${strings.getLanguage()}/purchase/${_id}`}
              />
            );
          })}
        </ul>
      </section>
      <button
        className="w-full h-[48px] flex justify-center items-center gap-[4px] bg-[#F7F7F7] rounded-[4px] mb-[60px]"
        onClick={() => navigate.push(`/${strings.getLanguage()}/explore`)}
      >
        <img src={effectIcon} alt="effet Icon" />
        <p className="text-[16px] font-bold">{strings.Home_MoreExplore}</p>
      </button>
      {bottomBanner && (
        <BaseBanner
          banner={bottomBanner}
          titleClass="text-[#462B7D]"
          subTitleClass="text-[#462B7D]"
        />
      )}
      <section className="mb-[59.5px] max-md:w-full max-md:px-[20px] mt-[60px]">
        <h2 className="text-[24px] font-semibold  max-md:hidden">
          {strings.Home_About_TIMEFLICK_NFTs}
        </h2>
        <div className="mt-[30px] flex md:justify-between justify-center max-md:flex-wrap gap-[30px]">
          {aboutTimeFlickLinks.map((link) => {
            return (
              <div className="relative max-md:w-full max-md:h-[170px] " key={link.href}>
                <img
                  src={link.src}
                  alt={link.alt}
                  className="rounded-[8px] max-md:w-full  max-md:h-[170px]"
                />
                <strong className="absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] font-semibold text-white text-[24px] w-max">
                  {link.content}
                </strong>
              </div>
            );
          })}
        </div>
        <p className="py-[30px] md:mt-[40px] text-[14px] md:text-[20px] text-center max-md:border-b-2 whitespace-pre-wrap ">
          {strings.Slowgan_1}
          <br />
          {strings.Slowgan_2}
          <br />
          {strings.Slowgan_3}
        </p>
      </section>
      <Footer />
      {modalOpen && (
        <Modal
          close={() => setModalOpen(false)}
          outsideClick={() => setModalOpen(false)}
          header={modalMessage}
        />
      )}
    </section>
  );
}

export default Home;
