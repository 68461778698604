import { PropsWithChildren, createContext, useContext, useEffect, useMemo, useRef } from 'react';
import { Web3Modal, useWeb3Modal } from '@web3modal/react';
import { useAccount, useContract, useSignMessage, useSigner } from 'wagmi';
import NFTCollection from '../abis/NFT.json';
import NFTMarketCollection from '../abis/NAMarket.json';
import TokenTransactions from '../modules/transactions/TokenTransactions';
import MarketTransactions from '../modules/transactions/MarketTransactions';
import StaticTransactions from '../modules/transactions/StaticTransactions';
import UserinfoContext from './UserinfoContext';
import { disconnectUserWallet, getUserWallet, registerUserWallet } from '../modules/API/WalletAPI';
import addresses from '../constants/addresses';
import { ethereumClient, projectId } from '../connection/walletConnect';
import { timeoutPromise } from '../utils/promiseUtils';

const staticTransactions = new StaticTransactions();

type Web3ContextType = {
  marketTransactions: MarketTransactions;
  tokenTransactions: TokenTransactions;
  staticTransactions: StaticTransactions;
  connectedWallet: string | null;
  requestSigning: (message: string) => Promise<void>;
  connect: () => Promise<void>;
  disconnect: () => Promise<void>;
  isMainnet: boolean;
};

export const MetamaskContext = createContext<Web3ContextType>({
  marketTransactions: new MarketTransactions(),
  tokenTransactions: new TokenTransactions(),
  staticTransactions,
  connectedWallet: null,
  requestSigning: async () => undefined,
  connect: async () => undefined,
  disconnect: async () => undefined,
  isMainnet: false,
});

export function WalletConnectContextProvider({ children }: PropsWithChildren<unknown>) {
  const { open } = useWeb3Modal();
  const { address, status, connector } = useAccount();
  const { data: signer } = useSigner();
  const { user } = useContext(UserinfoContext);
  const { signMessageAsync } = useSignMessage();
  const overrideWallet = useRef(false);

  const token = useContract({
    address: addresses.nft,
    abi: NFTCollection,
    signerOrProvider: signer,
  });

  const market = useContract({
    address: addresses.nftMarket,
    abi: NFTMarketCollection,
    signerOrProvider: signer,
  });

  const contextValues = useMemo<Web3ContextType>(() => {
    const contracts =
      market != null && token != null
        ? { nftMarketContract: market, nftContract: token }
        : undefined;

    const isMainnet = !!connector?.chains?.some(({ id }) => id === 1);

    return {
      tokenTransactions: new TokenTransactions(contracts, address),
      marketTransactions: new MarketTransactions(contracts, address),
      connectedWallet: address || null,
      staticTransactions,
      requestSigning: async (message: string) => timeoutPromise(signMessageAsync({ message })),
      connect: async () => {
        if (user == null) return;
        overrideWallet.current = true;
        open();
      },
      disconnect: async () => {
        await ethereumClient.disconnect();
        if (user != null) await disconnectUserWallet(user?._id);
        if (window.Appbridge != null) window.Appbridge.onSignOutWallet();
      },
      isMainnet,
    };
  }, [token, market, address, user, connector]);

  useEffect(() => {
    if (user == null) return;
    (async () => {
      if (status === 'connected') {
        const wallet = await getUserWallet(user._id);
        if (wallet == null || overrideWallet.current) {
          await registerUserWallet(user._id, address);
          if (window.Appbridge != null) window.Appbridge.onSignInWallet(address);
          return;
        }
        if (wallet?.walletAddress !== address) {
          await ethereumClient.disconnect();
        }
      }
    })();
  }, [status, user, address, overrideWallet.current]);

  return (
    <>
      <MetamaskContext.Provider value={contextValues}>{children}</MetamaskContext.Provider>
      <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
    </>
  );
}

export default MetamaskContext;
