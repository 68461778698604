import { Link } from 'react-router-dom';
import timeFlickLogo from '../assets/img/timeFlickLogo.svg';
import timeFlickAppIcon from '../assets/img/timeFlickAppIcon.svg';
import googlePlayIcon from '../assets/img/googlePlayIcon.svg';
import appStoreIcon from '../assets/img/appStoreIcon.svg';
import behanceIcon from '../assets/img/btn-behance.svg';
import mobileyoutubeIcon from '../assets/img/btn-youtube.svg';
import instaIcon from '../assets/img/btn-insta.svg';
import BeSnsIcon from '../assets/img/beSnsIcon.svg';
import youtubeIcon from '../assets/img/youtubeIcon.svg';
import instagramIcon from '../assets/img/instagramIcon.svg';
import { strings } from '../utils/Localization/LocalizationStrings';

function Footer() {
  return (
    <footer className="bg-gradient-to-r md:from-[#5db0b9] md:to-[#efefef] max-md:mb-[30px] max-md:flex max-md:justify-center ">
      <div className="flex md:w-full md:mx-auto md:py-[85.37px] max-md:flex-col max-md:gap-[24px] md:px-[120px]">
        <div className="max-md:h-[30px] md:w-[384px]">
          <img src={timeFlickLogo} alt="timeflick logo" className="mx-auto" />
          <p className="mt-[30px] text-[11px] font-medium max-md:hidden whitespace-pre-wrap">
            {strings.Footer_TimeFLIKPromotion}
          </p>
          <p className="mt-[14px] text-[11px] max-md:hidden">{strings.Footer_TimeFlicExplain}</p>
        </div>
        <div className="max-md:h-[40px] md:hidden flex justify-center flex-wrap w-full gap-[10px] ">
          <a href="https://www.be.com">
            <img src={behanceIcon} alt="behanceIcon" />
          </a>
          <a href="https://www.youtube.com">
            <img src={mobileyoutubeIcon} alt="youtubeIcon" />
          </a>
          <a href="https://www.instagram.com/">
            <img src={instaIcon} alt="instaIcon" />
          </a>
        </div>
        {/* <div className="md:hidden flex flex-wrap  gap-[19px] text-[11px]">
          <Link to="/faq">FAQ</Link>
        </div> */}
        <div className=" max-md:hidden md:w-[384px]">
          <h1 className="text-[22px] font-light">{strings.App_Download}</h1>
          <div className="flex gap-[9px] items-center mt-[20px]">
            <img src={timeFlickAppIcon} alt="timeflick app icon" />
            <img src={timeFlickLogo} alt="timeflick logo" className="h-[16.97px]" />
          </div>
          <p className="mt-[12px] text-[11px] font-medium whitespace-pre-wrap">
            {strings.Footer_TimeFlicAppDownloadNow}
          </p>
          <a
            href="https://play.google.com/store/apps/details?id=com.apposter.watchmaker&hl=ko&gl=US&pli=1"
            className="flex gap-[10px] items-center mt-[12px] px-[30px] py-[8px] bg-black rounded-[3px]  w-[179.2px] "
          >
            <img src={googlePlayIcon} alt="Google play Icon" />
            <span className="text-[#02A79D] font-medium text-[14px] w-[89px]">
              {strings.Google_Play}
            </span>
          </a>
          <a
            href="https://apps.apple.com/us/app/%ED%83%80%EC%9E%84%ED%94%8C%EB%A6%AD-timeflik-%EC%9B%8C%EC%B9%98%ED%8E%98%EC%9D%B4%EC%8A%A4/id1097434319?l=ko"
            className="flex gap-[10px] items-center mt-[12px] px-[30px] py-[8px] bg-black rounded-[3px] w-[179.2px]"
          >
            <img src={appStoreIcon} alt="Apple appstore Icon" />
            <span className="text-[#02A79D] font-medium  text-[14px] w-[89px]">
              {strings.App_Store}
            </span>
          </a>
        </div>
        <div className="max-md:hidden">
          <h1 className="text-[22px] font-light">{strings.Contact}</h1>
          <dl className="mt-[20px] text-[11px]">
            <dt>{strings.Footer_Contact_CompanyTitleText}</dt>
            <dd className="mt-[5px]">
              {strings.Footer_Contact_CompanyBusinessNumberTitle}:
              {strings.Footer_Contact_CompanyBusinessNumber}
            </dd>
            <dd className="mt-[5px]">
              {strings.Footer_Contact_CompanyReportNumberTitle} :
              {strings.Footer_Contact_CompanyReportNumber}
            </dd>
            <dd className="mt-[5px]">
              {strings.Footer_Contact_CompanyCEOTitle} : {strings.Footer_Contact_CompanyCEOName}
            </dd>
            <dd className="mt-[5px]">
              {strings.Footer_Contact_CompanyAddressTitle} : {strings.Footer_Contact_CompanyAddress}
            </dd>
            <dd className="mt-[5px]">
              {strings.Footer_Contact_CompanyTelTitle} :
              <address className="inline-block ml-[3px] not-italic">
                {strings.Footer_Contact_CompanyTelNumber}
              </address>
            </dd>
            <dd className="mt-[5px]">
              {strings.Footer_Contact_CompanyFaxTitle} :
              <address className="inline-block ml-[3px] not-italic">
                {strings.Footer_Contact_CompanyFaxNumber}
              </address>
            </dd>
          </dl>
          <a
            href="mailto:help@apposter.com"
            className="inline-block text-center w-[129.51px] h-[32px] py-[4px] mt-[12px] bg-black rounded-[3px] text-[14px] text-[#72B4C4]"
          >
            {strings.Contact_Us}
          </a>
        </div>
      </div>
      <div className="bg-gradient-to-r from-[#97bbd1] to-[#e2ebeb] max-md:hidden ">
        <div className="w-[1200px] h-[72px] mx-auto flex justify-between items-center md:px-[120px]">
          <span className="text-[11px] font-medium">{strings.Footer_CopyrightExplain}</span>
          <div className="flex justify-between items-center gap-[59px]">
            <Link to="/faq" className="text-[11px] font-medium">
              FAQ
            </Link>
            <a href="/terms.txt" className="text-[11px] font-medium">
              {strings.Terms_of_Use}
            </a>

            <a href="/privacy.txt" className="text-[11px] font-medium">
              {strings.Privacy_Policy}
            </a>

            <div className="flex gap-[7px]">
              <a
                href="https://www.be.com"
                className="flex justify-center items-center w-[35px] h-[35px] bg-black rounded-full"
              >
                <img src={BeSnsIcon} alt="Be sns icon" />
              </a>
              <a
                href="https://www.youtube.com"
                className="flex justify-center items-center w-[35px] h-[35px] bg-black rounded-full"
              >
                <img src={youtubeIcon} alt="youtube icon" />
              </a>
              <a
                href="https://www.instagram.com/"
                className="flex justify-center items-center w-[35px] h-[35px] bg-black rounded-full"
              >
                <img src={instagramIcon} alt="instagram icon" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
