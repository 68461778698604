import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { strings } from '../utils/Localization/LocalizationStrings';
import BaseButton from '../components/BaseButton';
import metaMaskFoxIcon from '../assets/img/metaMaskFoxIcon.svg';
import walletIcon from '../assets/img/walletIcon.svg';
import checkIcon from '../assets/img/checkIcon_checked.svg';
import UserinfoContext from '../context/UserinfoContext';
import WalletConnectContext from '../context/WalletConnectContext';

function ConnectWallet() {
  const [walletAddressCopied, setWalletAddressCopied] = useState(false);
  const [isMainet, setIsMainet] = useState(false);
  const { user } = useContext(UserinfoContext);
  const { isMainnet, connectedWallet, disconnect, connect } = useContext(WalletConnectContext);

  useEffect(() => {
    setIsMainet(isMainnet);
  }, [isMainnet]);

  return (
    <section className="md:fixed md:top-1/2 md:left-1/2 md:translate-x-[-50%] md:translate-y-[-50%] md:w-[500px] text-center flex flex-col align-middle max-md:px-3">
      <h1 className="text-[24px] font-bold max-md:py-4 max-md:my-10">
        {strings.ConnectWallet_TitleText}
      </h1>
      {connectedWallet != null ? (
        <>
          <div className="w-full h-[1px] mt-[29px] bg-[#DDDDDD]" />
          <div className="flex justify-between md:items-center pt-[32px] pb-[6px] max-md:flex-col">
            <div className="flex items-center">
              <img
                src={metaMaskFoxIcon}
                alt={strings.MetamaskWalletIcon}
                className="inline-block mr-[10px]"
              />
              <span className="text-[14px] font-bold mr-[13.4px]">
                {strings.ConnectWallet_MetamaskText}
              </span>
              <img src={checkIcon} alt={strings.CheckIcon} className="w-[24px]" />
            </div>
            <div className="relative max-md:flex-1 max-md:mt-[13px]  max-md:mb-[10px]">
              <span
                className={`opacity-0 absolute translate-y-[-50%] left-[15px] text-[#16BBA7] text-[12px] bg-white px-[10px] ${
                  walletAddressCopied && 'opacity-100'
                }`}
              >
                {strings.ConnectWallet_WalletCopyText}
              </span>
              <input
                className={`md:w-[300px] w-full h-[40px] pr-[16px] border border-[#B7C0C6] rounded-[4px] text-[#818990] font-medium text-right ${
                  walletAddressCopied && 'border-[#16BBA7]'
                }`}
                type="text"
                readOnly
                value={connectedWallet}
              />
            </div>
          </div>
          {!isMainet && <p className="text-center text-red-500 mb-2">{strings.usingTestnet}</p>}

          <div className="flex justify-end items-center">
            <CopyToClipboard text={connectedWallet} onCopy={() => setWalletAddressCopied(true)}>
              <button type="button" className="mr-[10px] text-[12px] hover:text-[#2ACFBB]">
                {strings.ConnectWallet_LinkCopyButton}
              </button>
            </CopyToClipboard>
            <div className="block w-[1px] h-[12px] bg-[#DDDDDD]" />
            <button
              type="button"
              className="ml-[10px] text-[12px] hover:text-[#2ACFBB]"
              onClick={async () => {
                await disconnect();
              }}
            >
              {strings.ConnectWallet_ConnectClearButton}
            </button>
          </div>
          <div className="w-full h-[1px] mt-[24px] bg-[#DDDDDD]" />
          <Link to={`/${strings.getLanguage()}/`}>
            <BaseButton
              type="button"
              className="md:w-[300px] md:h-[48px] max-md:h-[50px]  bg-[#2ACFBB] text-white mt-[50px]"
            >
              {strings.ConnectWallet_HomeButton}
            </BaseButton>
          </Link>
        </>
      ) : (
        <>
          <div className="flex justify-between items-center mt-[29px] py-8 md:py-[14px] border-y-[1px] border-[#DDDDDD]">
            <div>
              <img
                src={metaMaskFoxIcon}
                alt={strings.MetamaskWalletIcon}
                className="inline-block mr-[10px]"
              />
              <span className="text-[14px] font-bold">Metamask</span>
            </div>
            <button
              className="flex justify-center items-center gap-[5px] w-[68px] h-[32px] bg-[#2ACFBB] rounded-[4px] text-white text-[12px] font-bold"
              type="button"
              onClick={async () => {
                if (user == null) return;
                await connect();
              }}
            >
              <img src={walletIcon} alt={strings.WalletIcon} />
              {strings.ConnectWallet_ConnectButton}
            </button>
          </div>
          <Link to={`/${strings.getLanguage()}/`}>
            <BaseButton
              type="button"
              className="md:w-[300px] md:h-[48px] max-md:h-[50px]  bg-[#D6DADE] text-[#9CA4AC] mt-[50px]"
            >
              {strings.ConnectWallet_HomeButton}
            </BaseButton>
          </Link>
        </>
      )}
    </section>
  );
}

export default ConnectWallet;
