/* eslint-disable camelcase */
import LocalizedStrings, { LocalizedStringsMethods } from 'react-localization';

interface TestInterface extends LocalizedStringsMethods {
  searchSidebar_PriceText: string;
  searchSidebar_Button: string;
  searchSidebar_CategoryText: string;
  searchSidebar_OsText: string;
  searchSidebar_CategoryList_analog: string;
  searchSidebar_CategoryList_digital: string;
  searchSidebar_CategoryList_digilog: string;
  searchSidebar_CategoryList_informative: string;
  searchSidebar_OsList_android: string;
  searchSidebar_OsList_ios: string;
  searchSidebar_FillterText: string;
  Header_Explore: string;
  Header_Create: string;
  Header_MyDesign: string;
  Header_MyApp: string;
  Header_WalletConnect: string;
  Header_SingIn: string;
  Header_TimeFlicText: string;
  Header_BackText: string;
  Header_AndroidDownload: string;
  Header_IosDownload: string;
  Facefacelist_Loading: string;
  Facefacelist_SelectWatch: string;
  Facefacelist_ShowInspectionCompleted: string;
  Signin_PasswordResetText: string;
  Signin_PasswordResetButton: string;
  Signin_SocialLoginText: string;
  Signin_SocialLogin: string;
  And: string;
  to: string;
  You_agree: string;
  Signin_IshaveEmailText: string;
  SignupButton: string;
  SigninButton: string;
  PasswordRreset_InputEmailTerms: string;
  PasswordRreset_PasswordRestText: string;
  PasswordRreset_CheckEmail: string;
  PasswordRreset_SendResetPwdLink: string;
  Signup_TitleText: string;
  Signup_NickNameTerm: string;
  Signup_NickNameTerm2: string;
  EmailTerm: string;
  Signup_EmailSendButton: string;
  Signup_VerifystatementdButton: string;
  Signup_PasswordTerm_En: string;
  Signup_PasswordTerm_Num: string;
  Signup_PasswordTerm_Symbol: string;
  Signup_PasswordTerm_safety: string;
  AgreeUseTermCheckbox: string;
  AgreePrivacyTermCheckbox: string;
  Singup_VerifyStatementTerms: string;
  Singup_PasswordTerms: string;
  ViewMoreButton: string;
  NextButton: string;
  Next: string;
  SignUpCelebrate_TitleText: string;
  SignUpCelebrate_FindStarting: string;
  SignUpCelebrate_ForNftWhatchFace: string;
  SignUpCelebrate_ConnectWallet: string;
  SignUpCelebrate_WalletConnectButton: string;
  SignUpCelebrate_HomeButton: string;
  ConnectWallet_TitleText: string;
  ConnectWallet_MetamaskText: string;
  ConnectWallet_WalletCopyText: string;
  ConnectWallet_LinkCopyButton: string;
  ConnectWallet_ConnectClearButton: string;
  ConnectWallet_HomeButton: string;
  ConnectWallet_ConnectButton: string;
  ConnectWallet_MetamaskError: string;
  ConnectWallet_ConnectError: string;
  ConnectWallet_UnknownError: string;
  ConnectWallet_WalletNotConnected: string;
  Footer_TimeFLIKPromotion: string;
  Footer_TimeFlicExplain: string;
  Footer_TimeFlicAppDownloadNow: string;
  Footer_Contact_CompanyTitleText: string;
  Footer_Contact_CompanyBusinessNumberTitle: string;
  Footer_Contact_CompanyBusinessNumber: string;
  Footer_Contact_CompanyReportNumberTitle: string;
  Footer_Contact_CompanyReportNumber: string;
  Footer_Contact_CompanyCEOTitle: string;
  Footer_Contact_CompanyCEOName: string;
  Footer_Contact_CompanyAddressTitle: string;
  Footer_Contact_CompanyAddress: string;
  Footer_Contact_CompanyTelTitle: string;
  Footer_Contact_CompanyTelNumber: string;
  Footer_Contact_CompanyFaxTitle: string;
  Footer_Contact_CompanyFaxNumber: string;
  Footer_CopyrightExplain: string;
  Terms_of_Use: string;
  Privacy_Policy: string;
  Contact_Us: string;
  App_Store: string;
  Google_Play: string;
  App_Download: string;
  Contact: string;
  Home_WatchfaceMarketplaceExplain: string;
  CREATE_ITEM: string;
  New_Arrivals: string;
  Trending: string;
  Home_MoreExplore: string;
  Home_About_TIMEFLICK_NFTs: string;
  Home_AllowTimeFlicNft: string;
  HomeBannerTitle_IsFirstWatchspace: string;
  HomeBannerSubTitle_FrequentlyQuestions: string;
  Go_To_FAQ: string;
  Create_Item: string;
  Cancel_Sale: string;
  Details: string;
  Item_Activity: string;
  Event: string;
  Price: string;
  From: string;
  To: string;
  Date: string;
  Owned_By: string;
  To_Cancel: string;
  To_Delete: string;
  To_Sel: string;
  Purchase: string;
  Show_In_App: string;
  AdjustToWatch: string;
  ShowApp: string;
  HiddenFace: string;
  RepotedHiddenFace: string;
  HowToCancelHidden: string;
  CancleHiddenEmail: string;
  Item: string;
  Reason: string;
  Blockchain: string;
  ETH: string;
  Royalty: string;
  Commission: string;
  Commission_value: string;
  Total_Price: string;
  Unlockable_Content: string;
  Page: string;
  Not_Found: string;
  Error404_Detial: string;
  Go_back: string;
  Go_Home: string;
  Search: string;
  WatchCount: string;
  TIMEFLIK_NFT_Marketplace: string;
  ConnectWallet: string;
  Marketplace: string;
  TMEFLK: string;
  Logout: string;
  Banner_Precautions: string;
  CommonBanner: string;
  Cancel: string;
  Remove: string;
  Apply: string;
  Image: string;
  Arrival_URL: string;
  Title_Text: string;
  Sub_Text: string;
  Button_Text: string;
  Minting_Loading: string;
  MultiBanner: string;
  ReportedNoSearch: string;
  Thums: string;
  Reported: string;
  Reporter: string;
  ReportDate: string;
  ReportDetailReason: string;
  ReportAction: string;
  ReportActionDate: string;
  Report_Content: string;
  Report_Reson: string;
  ReportUnableCancel: string;
  Report_Slander: string;
  Report_Pornography: string;
  Report_Abuse: string;
  Report_DetailReasonTerms: string;
  Report_Received: string;
  Report_Etc: string;
  Report_Completed: string;
  Reminder_Time: string;
  Slowgan_1: string;
  Slowgan_2: string;
  Slowgan_3: string;
  AllRead: string;
  AllKeep: string;
  KeepCancel: string;
  KeepAlarm: string;
  CopyItemLinkModal_Title: string;
  ConfirmDeleteMdal_Title: string;
  LogoutModal_Title: string;
  RemoveContentModal_Title: string;
  ApplyContentModal_Title: string;
  AdminLoginFailModal_Title: string;
  CancellSaleModal_Title: string;
  CreateWatchModal_Title: string;
  LanguageCodeInput: string;
  WritingList: string;
  CurrentRealServerList: string;
  ListBeingEdited: string;
  ServiceApply: string;
  ServiceReservation: string;
  TopMultiBanner: string;
  MiddleMultiBanner: string;
  BottomMultiBanner: string;
  Banner_NoTitle: string;
  InputSearch: string;
  SearchClearButtonIncon: string;
  YearMonthDayUnit: string;
  AdminReport_NoSearchIcon: string;
  MetamaskWalletIcon: string;
  CheckIcon: string;
  WalletIcon: string;
  GoBackIcon: string;
  MenuOpenIcon: string;
  MenuCloseIcon: string;
  NotificationIcon: string;
  ArrowIcon: string;
  NotificationedNotificationIcon: string;
  CloseButton: string;
  Locker: string;
  Inbox: string;
  Newest: string;
  PriceHigh: string;
  PriceLowest: string;
  AdminReport_Select: string;
  AdminReport_HiddenKeep: string;
  AdminReport_HiddenClear: string;
  SearchLoading: string;
  SearchNoRsCreate: string;
  SearchNoRsSale: string;
  SearchNoRsOwned: string;
  SearchNoRsHidden: string;
  SearchNoRsExplore: string;
  Purchase_Loading: string;
  PurchaseProgress: string;
  PurchaseComplete: string;
  PurchaseFailed: string;
  SellFailed: string;
  Confirm: string;
  SignUpCelebrate_FirecrackerIcon: string;
  Detail_Loading: string;
  Enter_Email: string;
  Enter_Password: string;
  Enter_NickName: string;
  Enter_VerifyStatement: string;
  Enter_Code: string;
  EmailResetTerms: string;
  Check_Email: string;
  Check_Code: string;
  Signup_CheckCertification: string;
  Go_Back: string;
  Signup_Agreeeterms: string;
  Signup_Verificationcompleted: string;
  Signup_CheckVerification: string;
  Signup_CheckPassword: string;
  Signup_CheckDuplicateEmail: string;
  Signup_InvalidNickname: string;
  Signup_Signup: string;
  Signin_Signin: string;
  UserPage_Loading: string;
  Explore_Loading: string;
  Explore_NoSearch: string;
  Explore_NoSearchResult: string;
  AgreeTermsModal_TitleText: string;
  AgreeTermsModal_TitleDetailText: string;
  AgreeTermsModal_AllAgree: string;
  AgreeTermsModal_ButtonText: string;
  AgreeTermsModal_AgreeUseTerm: string;
  AgreeTermsModal_AgreePrivacyTerm: string;
  SignUpCelebrate_Greetings: string;
  Signup_EmailTerm2: string;
  Signup_NickNameFocusTerms: string;
  Royalty_Terms: string;
  Singup_NickNameTerms: string;
  HiddenItem: string;
  ReportReason: string;
  ContactUnhide: string;
  HiddenContent: string;
  usingTestnet: string;
  WorkInProcess: string;
  networkError: string;
  mintingError: string;
  chooseDate: string;
  reservationTime: string;
  reservationList: string;
  proceedPurchase: string;
  signingTimeout: string;
  approvalAll: string;
  mintNFT: string;
  proceedSell: string;
  userCancel: string;
  emailSent: string;
  emailSentFail: string;
}
export const strings: TestInterface = new LocalizedStrings({
  kr: {
    searchSidebar_PriceText: '가격',
    searchSidebar_Button: '적용',
    searchSidebar_CategoryText: '카테고리',
    searchSidebar_OsText: 'OS',
    searchSidebar_CategoryList_analog: '아날로그',
    searchSidebar_CategoryList_digital: '디지털',
    searchSidebar_CategoryList_digilog: '디지로그',
    searchSidebar_CategoryList_informative: '인포머티브',
    searchSidebar_OsList_android: '안드로이드',
    searchSidebar_OsList_ios: 'iOS',
    searchSidebar_FillterText: '필터',
    Header_Explore: '탐색',
    Header_Create: '생성',
    Header_MyDesign: '나만의 디자인',
    Header_MyApp: '앱 다운로드',
    Header_WalletConnect: '월렛 연결하기',
    Header_SingIn: '로그인',
    Header_TimeFlicText: '타임플릭앱',
    Header_BackText: '으로 돌아가기',
    Header_AndroidDownload: '안드로이드앱 다운로드',
    Header_IosDownload: 'iOS앱 다운로드',
    Facefacelist_Loading: '생성 가능 워치페이스 목록을 불러오고 있습니다...',
    Facefacelist_SelectWatch: ' 아이템으로 생성할 워치페이스를 선택하세요.',
    Facefacelist_ShowInspectionCompleted: ' 플릭스튜디오에서 검수 완료된 페이스만 볼수 있습니다.',
    Signin_PasswordResetText: '비밀번호를 잊으셨나요?',
    Signin_PasswordResetButton: '비밀번호 재설정',
    Signin_SocialLoginText: '소셜 계정으로 로그인',
    Signin_SocialLogin: '소셜 로그인 시 ',
    And: '및 ',
    to: '에',
    You_agree: '동의하신 것으로 간주합니다',
    Signin_IshaveEmailText: '아직 계정이 없으신가요?',
    SignupButton: '회원가입',
    SigninButton: '로그인',
    PasswordRreset_InputEmailTerms: '가입시 기재한 이메일을 입력해주세요.',
    PasswordRreset_PasswordRestText: '비밀번호 재설정',
    PasswordRreset_CheckEmail: '메일을 확인해주세요',
    PasswordRreset_SendResetPwdLink: '비밀번호 재설정 링크를 보냈어요.',
    Signup_TitleText: '회원가입',
    Signup_NickNameTerm: '*닉네임(최소2글자)',
    Signup_NickNameTerm2: '20bytes 이내로 작성해주세요.',
    EmailTerm: ' *이메일',
    Signup_EmailTerm2: ' 인증을 위해 정확히 입력해주세요',
    Signup_EmailSendButton: '발송',
    Signup_VerifystatementdButton: '인증',
    Signup_PasswordTerm_En: '영문',
    Signup_PasswordTerm_Num: '숫자',
    Signup_PasswordTerm_Symbol: '특수기호',
    Signup_PasswordTerm_safety: '안전도',
    AgreeUseTermCheckbox: '이용약관 동의 (필수)',
    AgreePrivacyTermCheckbox: '개인정보처리방침 동의 (필수)',
    Singup_NickNameTerms: '*닉네임(최소 2글자)',
    Signup_NickNameFocusTerms: '영문, 한글, 숫자 사용 가능',
    Singup_VerifyStatementTerms: '*인증 문구',
    Singup_PasswordTerms: '*비밀번호',
    ViewMoreButton: '더보기',
    NextButton: '다음',
    Next: '계속',
    SignUpCelebrate_TitleText: '가입 완료',
    SignUpCelebrate_Greetings: '안녕! 만나서 반가워요',
    SignUpCelebrate_FindStarting: '이제 나만의 페이스를 찾아볼까요?',
    SignUpCelebrate_ForNftWhatchFace: 'NFT 워치 페이스 구매를 위해서는',
    SignUpCelebrate_ConnectWallet: '월렛을 연결해야 해요.',
    SignUpCelebrate_WalletConnectButton: '월렛 연결하기',
    SignUpCelebrate_HomeButton: '홈으로 이동하기',
    ConnectWallet_TitleText: '월렛 연결',
    ConnectWallet_MetamaskText: 'Metamask',
    ConnectWallet_WalletCopyText: '월렛 주소를 복사하였습니다',
    ConnectWallet_LinkCopyButton: '링크 복사',
    ConnectWallet_ConnectClearButton: '연결 해제',
    ConnectWallet_HomeButton: '홈으로 이동하기',
    ConnectWallet_ConnectButton: '연결',
    ConnectWallet_MetamaskError: '메타마스크 설치가 필요합니다.',
    ConnectWallet_ConnectError: '월렛이 연결되지 않았습니다.',
    ConnectWallet_UnknownError: '월렛 연결에 실패했습니다. 다시 시도해주세요.',
    Footer_TimeFLIKPromotion:
      '전세계의 사용자들이 만드는 수백만개의 워치페이스 부터,\n다양한 디자인과 높은 품질의 스트랩 악세서리 까지.\n지금, 세상에 하나밖에 없는 나만의 시계를 전세계 사용자들과\n공유해보세요.',
    Footer_TimeFlicExplain: '*TIMEFLIK(TM)은 지난 10년간 걸어왔던\nMR TIME의 새로운 이름입니다.',
    Footer_TimeFlicAppDownloadNow:
      '수많은 혜택이 기다리고 있는 타임플릭앱을 지금 \n 다운로드 받아보세요!',
    Footer_Contact_CompanyTitleText: '주식회사 앱포스터',
    Footer_Contact_CompanyBusinessNumberTitle: '사업자 등록번호',
    Footer_Contact_CompanyBusinessNumber: '107-87-39753',
    Footer_Contact_CompanyReportNumberTitle: '통신판매업신고번호',
    Footer_Contact_CompanyReportNumber: '제 2013-서울마포-1337호',
    Footer_Contact_CompanyCEOTitle: '대표이사',
    Footer_Contact_CompanyCEOName: '경성현',
    Footer_Contact_CompanyAddressTitle: '주소',
    Footer_Contact_CompanyAddress: '서울특별시 마포구 성암로 189, DMC 타워 7층',
    Footer_Contact_CompanyTelTitle: '대표전화',
    Footer_Contact_CompanyTelNumber: '1666 - 9015',
    Footer_Contact_CompanyFaxTitle: '팩스',
    Footer_Contact_CompanyFaxNumber: ' 02-538-0344',
    Footer_CopyrightExplain: 'Copyright 2023 주식회사 앱포스터. All Rights Reserved.',
    Terms_of_Use: '이용약관',
    Privacy_Policy: '개인정보처리방침',
    Contact_Us: '문의하기',
    App_Store: '앱 스토어',
    Google_Play: '구글 플레이',
    App_Download: '앱 다운로드',
    Contact: '연락처',
    Home_WatchfaceMarketplaceExplain:
      '창의적인 NFT 디지털 자산을 수집, 구매 및 판매할 수 있는 최대 규모의 Watchface 마켓플레이스',
    CREATE_ITEM: '만들기',
    New_Arrivals: '최신',
    Trending: '인기 급상승',
    Home_MoreExplore: '더 많은 NFT 시계 탐색',
    Home_About_TIMEFLICK_NFTs: 'TIMEFLIK NFT 소개',
    Home_AllowTimeFlicNft:
      'TIMEFLIK NFT Watch Faces allow you Blahblahblah supercalifrigerlisticaspialidocious \n  Blahblahblah supercalifrigerlisticaspialidocious',
    HomeBannerTitle_IsFirstWatchspace: 'NFT 워치스페이스,처음이시죠?',
    HomeBannerSubTitle_FrequentlyQuestions: '자주 묻는 질문(FAQ)',
    Go_To_FAQ: ' FAQ 바로가기',
    Create_Item: '아이템 생성하기',
    Cancel_Sale: '판매 취소',
    Details: '세부',
    Item_Activity: '품목 활동',
    Event: '이벤트',
    Price: '가격',
    From: 'From',
    To: 'To',
    Date: '일시',
    Owned_By: '소유됨',
    To_Cancel: '판매 취소',
    To_Delete: '삭제하기',
    To_Sel: '판매하기',
    Purchase: '구매하기',
    ShowApp: '앱에서 보기',
    HiddenFace: '숨김 처리된 컨텐츠 입니다',
    RepotedHiddenFace: '신고 처리되어 숨겨진 페이스입니다.',
    HowToCancelHidden: '숨김 해제를 위해서는 고객센터로 문의해주세요.',
    CancleHiddenEmail: 'help@apposter.com',
    Item: '항목',
    Reason: '사유',
    Blockchain: '블록체인',
    ETH: 'ETH',
    Royalty: 'Royalty',
    Royalty_Terms: '10% 범위 내에서 소수점 둘째자리까지 입력 가능합니다.',
    Commission: '수수료',
    Commission_value: '2.5%',
    Total_Price: '총 가격',
    Unlockable_Content: '잠금 해제 가능한 콘텐츠',
    Page: '페이지',
    Not_Found: ' 찾을 수 없음',
    Error404_Detial: '요청하신 페이지를 찾을 수 없습니다',
    Go_back: '이전 페이지로 이동',
    Go_Home: '홈으로 이동하기',
    Search: '검색',
    WatchCount: '워치페이스',
    TIMEFLIK_NFT_Marketplace: 'TIMEFLIK NFT 마켓플레이스',
    ConnectWallet: ' 관리자 월렛 연결하기',
    Marketplace: '마켓플레이스',
    TMEFLK: 'T/MEFL/K',
    Logout: 'Logout',
    Banner_Precautions: '* 한 항목을 완전히 저장하신 후,\n 새로운 항목을 만드세요',
    CommonBanner: '일반배너',
    Cancel: '취소',
    Remove: '삭제',
    Apply: '적용',
    Image: '이미지',
    Arrival_URL: '도착 URL',
    Title_Text: '제목 텍스트',
    Sub_Text: '소제목 텍스트',
    Button_Text: '버튼 텍스트',
    Minting_Loading: '민팅 작업 진행중 입니다...',
    MultiBanner: '멀티배너',
    ReportedNoSearch: '등록된 신고 내역이 없습니다.',
    Thums: '썸네일',
    Reported: '신고 대상자',
    Reporter: '신고자',
    ReportDate: '신고 일시',
    ReportDetailReason: '상세 사유',
    ReportAction: '조치',
    ReportActionDate: '조치 일시',
    Report_Content: '컨텐츠 신고',
    Report_Reson: '신고 사유',
    ReportUnableCancel: '신고 시 신고 취소 불가합니다.',
    Report_Slander: '특정인 비방',
    Report_Pornography: '음란물',
    Report_Abuse: '욕설',
    Report_DetailReasonTerms: '신고 상세 사유를 기재해주세요.',
    Report_Received: '신고가 접수되었습니다.',
    Report_Etc: '기타',
    Report_Completed: '신고완료',
    Reminder_Time: '알림 시간',
    Slowgan_1: '당신을 위한 시계 모드 NFT를 찾아보세요!',
    Slowgan_2: `TIMEFLIK NFT 마켓플레이스를 통해 자신만의 시계 모드를 만들고 독점 상품을 찾아볼 수 있습니다.`,
    Slowgan_3: '또는 모바일 앱에서 시계에 적용하는 것도 가능합니다!',
    AllRead: '모두 읽기',
    AllKeep: '모두 보관',
    KeepCancel: '보관 취소',
    KeepAlarm: '알람 보관',
    CopyItemLinkModal_Title: '아이템의 링크가\n복사되었습니다',
    ConfirmDeleteMdal_Title: '삭제된 페이스는 복구되지 않습니다.\n 삭제를 진행하시겠습니까?',
    LogoutModal_Title: '로그아웃이 되었습니다',
    RemoveContentModal_Title: '콘텐츠가 삭제되었습니다',
    ApplyContentModal_Title: '콘텐츠가 적용되었습니다',
    AdminLoginFailModal_Title: '관리자 권한이 없습니다',
    CancellSaleModal_Title: '판매 취소 하시겠습니까?',
    CreateWatchModal_Title: '아이템 생성 완료!\n 마이페이지에서 확인 후 판매도 시도해보세요',
    LanguageCodeInput: '언어코드 입력',
    WritingList: '편집중인 목록',
    CurrentRealServerList: '현재 실서버 목록',
    ListBeingEdited: '편집 중인 목록',
    ServiceApply: '서비스 적용',
    ServiceReservation: '서비스 예약',
    TopMultiBanner: '상단 멀티 배너',
    MiddleMultiBanner: '중단 띠배너',
    BottomMultiBanner: '하단 띠배너',
    Banner_NoTitle: '제목 없음',
    InputSearch: '검색어를 입력해주세요',
    SearchClearButtonIncon: '검색어 삭제 버튼 아이콘 이미지',
    YearMonthDayUnit: '년.월.일',
    AdminReport_NoSearchIcon: '신고내역 검색 아이콘',
    MetamaskWalletIcon: '메타마스크 지갑 아이콘',
    CheckIcon: '체크 아이콘',
    WalletIcon: '지갑 아이콘',
    GoBackIcon: '뒤로가기 아이콘',
    MenuOpenIcon: '메뉴 열기 아이콘',
    MenuCloseIcon: '메뉴 닫기 아이콘',
    NotificationIcon: '알람 아이콘',
    ArrowIcon: '셀렉트 박스 화살표',
    NotificationedNotificationIcon: '알람알림 아이콘',
    CloseButton: '닫기 버튼',
    Locker: '보관함',
    Inbox: '수신함',
    Newest: '최신순',
    PriceHigh: '가격높은순',
    PriceLowest: '가격낮은순',
    AdminReport_Select: '선택',
    AdminReport_HiddenKeep: '숨김 유지',
    AdminReport_HiddenClear: '숨김 해제',
    SearchLoading: '워치페이스를 조회중입니다.',
    SearchNoRsCreate: '생성 가능한 워치페이스가 없습니다.',
    SearchNoRsSale: '판매중인 워치페이스가 없습니다.',
    SearchNoRsOwned: '구매한 워치페이스가 없습니다.',
    SearchNoRsHidden: '숨겨진 워치페이스가 없습니다.',
    SearchNoRsExplore: '등록된 워치페이스가 없습니다.',
    Purchase_Loading: '구매 작업 진행중 입니다...',
    PurchaseProgress: '구매가 진행중입니다.\n잠시만 기다려주세요',
    PurchaseComplete: '구매가 완료되었습니다.',
    PurchaseFailed: '구매에 실패하였습니다.\n다시 시도해주세요.',
    SellFailed: '판매에 실패하였습니다.\n다시 시도해주세요.',
    Confirm: '확인',
    SignUpCelebrate_FirecrackerIcon: '웰컴 폭죽 이미지',
    Detail_Loading: '상세정보를 불러오고 있습니다...',
    Enter_Email: '이메일을 입력해주세요',
    Enter_Password: '비밀번호를 입력해주세요',
    Enter_NickName: '닉네임을 입력해주세요',
    Enter_VerifyStatement: '인증문구를 입력해주세요',
    Enter_Code: '코드를 입력해주세요',
    EmailResetTerms: '이메일 변경시 재인증이 필요합니다.',
    Check_Email: '해당 이메일이 존재하지 않습니다.',
    Check_Code: '코드가 일치하지 않습니다.',
    Signup_CheckCertification: '인증을 하지 않으셨습니다.',
    Go_Back: '뒤로',
    Signup_Agreeeterms: '약관에 동의하지 않으셨습니다.',
    Signup_Verificationcompleted: '인증완료',
    Signup_CheckVerification: '인증문구를 다시 한번 확인해주세요',
    Signup_CheckPassword: '패스워드가 일치하지 않습니다.',
    Signup_CheckDuplicateEmail: '중복된 이메일입니다.',
    Signup_InvalidNickname: '사용할 수 없는 닉네임입니다.',
    Signup_Signup: '회원가입에 실패하셨습니다 다시 시도 해주세요',
    Signin_Signin: '로그인에 실패하셨습니다 다시 시도 해주세요',
    UserPage_Loading: '사용자 페이지 정보를 불러오고 있습니다...',
    Explore_Loading: '워치페이스 목록을 불러오고 있습니다...',
    Explore_NoSearch: '찾으시는 워치페이스 정보를 검색해주세요.',
    Explore_NoSearchResult: '검색 정보와 일치하는 워치페이스가 없습니다.',
    AgreeTermsModal_TitleText: '이용약관 동의',
    AgreeTermsModal_TitleDetailText: '이용약관 및 개인정보처리방침 동의',
    AgreeTermsModal_AllAgree: '전체 동의',
    AgreeTermsModal_ButtonText: '가입 완료',
    AgreeTermsModal_AgreeUseTerm:
      '타임플릭(TIMEFLIK) 이용약관\n\n제1조(목적)\n\n이 약관은 (주)앱포스터(이하 ‘회사’라 합니다)가 제공하는 모든 서비스와 이와 연계된 모바일 애플리케이션 서비스("타임플',
    AgreeTermsModal_AgreePrivacyTerm:
      '개인정보처리방침\n\n주식회사 앱포스터(이하 “회사”)는 이용자의 개인정보를 보호하고, 이와 관련된 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립하고 공개합니다. ',
    HiddenItem: '신고 처리되어 숨겨진 페이스입니다.',
    ReportReason: '사유',
    ContactUnhide: '숨김 해제를 위해서는 고객센터로 문의해주세요.',
    HiddenContent: '숨김 처리된 컨텐츠 입니다',
    Show_In_App: '앱에서 보기',
    AdjustToWatch: '워치에 적용하기',
    usingTestnet: '테스트넷 사용중입니다.',
    WorkInProcess: '작업 진행중 입니다...\n잠시만 기다려 주세요',
    ConnectWallet_WalletNotConnected: '지갑이 연결되지 않았습니다. 먼저 지갑을 연결해주세요.',
    networkError: '네트워크 오류가 발생했습니다. 잠시 후 다시 시도해주세요.',
    mintingError: '파일을 업로드하는 중에 문제가 발생했습니다.',
    chooseDate: 'Choose a date',
    reservationTime: '예약 시간 HH:mm',
    reservationList: '예약 리스트',
    proceedPurchase: '구매를 진행합니다.',
    signingTimeout: '서명 시간이 초과되었습니다. 다시 시도해주세요.',
    approvalAll:
      'NFT 토큰에 대한 액세스 권한이 필요합니다.\n 판매를 계속하려면 모든 권한을 승인해주세요.',
    mintNFT: 'NFT 토큰을 생성합니다.',
    proceedSell: '판매를 진행합니다.',
    userCancel: '취소하셨습니다.',
    emailSent: '이메일이 발송되었습니다.',
    emailSentFail: '이메일 발송에 실패했습니다.',
  },
  en: {
    searchSidebar_PriceText: 'Price',
    searchSidebar_Button: 'Apply',
    searchSidebar_CategoryText: 'Category',
    searchSidebar_OsText: 'OS',
    searchSidebar_CategoryList_analog: 'Analog',
    searchSidebar_CategoryList_digital: 'Digital',
    searchSidebar_CategoryList_digilog: 'Digilog',
    searchSidebar_CategoryList_informative: 'Informative',
    searchSidebar_OsList_android: 'Android',
    searchSidebar_OsList_ios: 'IOS',
    searchSidebar_FillterText: 'Filter',
    Header_Explore: 'Explore',
    Header_Create: 'Create',
    Header_MyDesign: 'Design your own',
    Header_MyApp: 'Download Our App',
    Header_WalletConnect: 'Connect wallet',
    Header_SingIn: 'Sign In',
    Header_TimeFlicText: 'TIMEFLIK App',
    Header_BackText: 'Back to',
    Header_AndroidDownload: 'Android Download',
    Header_IosDownload: 'iOS Download',
    Facefacelist_Loading: 'Loading the list of watch faces that can be created...',
    Facefacelist_SelectWatch: ' Select the watch face you want to create as an item.',
    Facefacelist_ShowInspectionCompleted:
      ' You can only view faces that have been inspected in Flix Studio.',
    Signin_PasswordResetText: 'Forgot your password?',
    Signin_PasswordResetButton: 'Reset password',
    Signin_SocialLoginText: 'Log in with your social account',
    Signin_SocialLogin: 'When logging in with social ',
    And: 'and ',
    to: 'to',
    You_agree: 'We assume that you agree.',
    Signin_IshaveEmailText: `Don't have an account yet?`,
    SignupButton: 'Sign up',
    SigninButton: 'Login',
    PasswordRreset_InputEmailTerms: 'Please enter the email address you provided when signing up.',
    PasswordRreset_PasswordRestText: 'Reset password',
    PasswordRreset_CheckEmail: 'Please check your email',
    PasswordRreset_SendResetPwdLink: `I've sent you a password reset link.`,
    Signup_TitleText: 'Sign up',
    Signup_NickNameTerm: '*Nickname (minimum 2 characters)',
    Signup_NickNameTerm2: 'Please write within 20 bytes.',
    EmailTerm: ' *email',
    Signup_EmailTerm2: ' Please enter the correct information for verification.',
    Signup_EmailSendButton: 'Send',
    Signup_VerifystatementdButton: 'Verfication',
    Signup_PasswordTerm_En: 'English',
    Signup_PasswordTerm_Num: 'Number',
    Signup_PasswordTerm_Symbol: 'Special symbol',
    Signup_PasswordTerm_safety: 'Safety',
    AgreeUseTermCheckbox: 'Agree to Terms of Use (required)',
    AgreePrivacyTermCheckbox: 'Agree to privacy policy (required)',
    Singup_NickNameTerms: '*Nickname (minimum 2 letters)',
    Signup_NickNameFocusTerms: 'English, Korean, and numbers available',
    Singup_VerifyStatementTerms: '*Verfication Text',
    Singup_PasswordTerms: '*Password',
    ViewMoreButton: 'More',
    NextButton: 'Next',
    Next: 'Next',
    SignUpCelebrate_TitleText: 'Sign up completed',
    SignUpCelebrate_Greetings: 'Hi! Nice to meet you',
    SignUpCelebrate_FindStarting: 'Now, shall we find our own pace?',
    SignUpCelebrate_ForNftWhatchFace: 'To purchase an NFT watch face',
    SignUpCelebrate_ConnectWallet: 'You need to connect your wallet.',
    SignUpCelebrate_WalletConnectButton: 'Connect your wallet',
    SignUpCelebrate_HomeButton: 'Go to Home',
    ConnectWallet_TitleText: 'Wallet Connection',
    ConnectWallet_MetamaskText: 'Metamask',
    ConnectWallet_WalletCopyText: 'Wallet address copied',
    ConnectWallet_LinkCopyButton: 'Copy link',
    ConnectWallet_ConnectClearButton: 'Disconnect',
    ConnectWallet_HomeButton: 'Go to Home',
    ConnectWallet_ConnectButton: 'Connect',
    ConnectWallet_MetamaskError: 'MetaMask installation is required.',
    ConnectWallet_ConnectError: 'Wallet is not connected.',
    ConnectWallet_UnknownError: 'Wallet connection failed. please try again.',
    Footer_TimeFLIKPromotion:
      'From millions of watch faces created by users around the world, to various designs and high-quality strap accessories. \nNow, share your one-of-a-kind watch with users around the world.',
    Footer_TimeFlicExplain:
      '*TIMEFLIK(TM) is the new name of MR TIME, which has been around for the past 10 years.',
    Footer_TimeFlicAppDownloadNow:
      'Download the Time Flick app now, where numerous benefits await!',
    Footer_Contact_CompanyTitleText: 'Appposter.Inc.',
    Footer_Contact_CompanyBusinessNumberTitle: 'Business Registration No',
    Footer_Contact_CompanyBusinessNumber: '107-87-39753',
    Footer_Contact_CompanyReportNumberTitle: 'E-commerce Permit No',
    Footer_Contact_CompanyReportNumber: '제2013-서울마포-1337호',
    Footer_Contact_CompanyCEOTitle: 'CEO',
    Footer_Contact_CompanyCEOName: 'Sunghyun Kyung',
    Footer_Contact_CompanyAddressTitle: 'Address',
    Footer_Contact_CompanyAddress:
      ' 12F, DMC Tower, 189, Seongam-ro, Mapo-gu, Seoul, Republic of Korea',
    Footer_Contact_CompanyTelTitle: 'Phone',
    Footer_Contact_CompanyTelNumber: '1666 - 9015',
    Footer_Contact_CompanyFaxTitle: 'Fax',
    Footer_Contact_CompanyFaxNumber: ' 02-538-0344',
    Footer_CopyrightExplain: 'Copyright 2023 Apposter.Inc. All Rights Reserved.',
    Terms_of_Use: 'Terms and conditions',
    Privacy_Policy: 'Privacy policy',
    Contact_Us: 'Contact Us',
    App_Store: 'App Store',
    Google_Play: 'Google Play',
    App_Download: 'App Download',
    Contact: 'Contact',
    Home_WatchfaceMarketplaceExplain:
      'Largest Watchface Marketplace To Collect, Buy and Sell Creative NFTs Digital Assets',
    CREATE_ITEM: 'CREATE ITEM',
    New_Arrivals: 'New Arrivals',
    Trending: 'Trending',
    Home_MoreExplore: 'Explore More NFT Watch Faces',
    Home_About_TIMEFLICK_NFTs: 'About TIMEFLIK NFTs',
    Home_AllowTimeFlicNft:
      'TIMEFLIK NFT Watch Faces allow youNFT 워치스페이스,처음이시죠? Blahblahblah supercalifrigerlisticaspialidocious \n  Blahblahblah supercalifrigerlisticaspialidocious',
    HomeBannerTitle_IsFirstWatchspace: 'Is this your first time using NFT Watchspace?',
    HomeBannerSubTitle_FrequentlyQuestions: 'FAQ',
    Go_To_FAQ: ' Go to FAQ',
    Create_Item: 'Creating an item',
    Cancel_Sale: 'Cancel sale',
    Details: 'Details',
    Item_Activity: 'Item Activity',
    Event: 'Event',
    Price: 'Price',
    From: 'From',
    To: 'To',
    Date: 'Date',
    Owned_By: 'Owned By',
    To_Cancel: 'Cancel sale',
    To_Delete: 'Delete',
    To_Sel: 'Sale',
    Purchase: 'Purchase',
    ShowApp: 'View in app',
    HiddenFace: 'This is hidden content.',
    RepotedHiddenFace: 'This face has been reported and hidden.',
    HowToCancelHidden: 'To unhide it, please contact customer service.',
    CancleHiddenEmail: 'help@apposter.com',
    Item: 'Item',
    Reason: 'Reason',
    Blockchain: 'Blockchain',
    ETH: 'ETH',
    Royalty: 'Royalty',
    Royalty_Terms: 'You can enter up to two decimal places within the 10% range.',
    Commission: 'Commission',
    Commission_value: '2.5%',
    Total_Price: 'Total Price',
    Unlockable_Content: 'Unlockable Content',
    Page: 'Page',
    Not_Found: ' Not Found',
    Error404_Detial: 'The page you requested cannot be found',
    Go_back: 'Go to previous page',
    Go_Home: 'Go Home',
    Search: 'Searh',
    WatchCount: 'watch faces(s)',
    TIMEFLIK_NFT_Marketplace: 'TIMEFLIK NFT Marketplace',
    ConnectWallet: ' Connect administrator wallet',
    Marketplace: 'Marketplace',
    TMEFLK: 'T/MEFL/K',
    Logout: 'Logout',
    Banner_Precautions: '* After completely saving an item\n create a new item',
    CommonBanner: 'General banner',
    Cancel: 'Cancel',
    Remove: 'Remove',
    Apply: 'Apply',
    Image: 'Image',
    Arrival_URL: 'Destination URL',
    Title_Text: 'Title text',
    Sub_Text: 'Subheading text',
    Button_Text: 'Button text',
    Minting_Loading: 'Minting work is in progress...',
    MultiBanner: 'Multi banner',
    ReportedNoSearch: 'There are no registered reports.',
    Thums: 'Thumbnail',
    Reported: 'Reported',
    Reporter: 'Reporter',
    ReportDate: 'Report date',
    ReportDetailReason: 'Detailed reason',
    ReportAction: 'Action',
    ReportActionDate: 'Action date',
    Report_Content: 'Report content',
    Report_Reson: 'Reason for reporting',
    ReportUnableCancel: 'Once reported, the report cannot be cancelled.',
    Report_Slander: 'Slandering a specific person',
    Report_Pornography: 'Pornography',
    Report_Abuse: 'Abuse',
    Report_DetailReasonTerms: 'Please provide detailed reason for reporting.',
    Report_Received: 'A report has been received.',
    Report_Etc: 'Etc',
    Report_Completed: 'Report completed',
    Reminder_Time: 'Reminder time',
    Slowgan_1: 'Discover watch face NFTs for you!',
    Slowgan_2: `TIMEFLIK NFT marketplace allows you to mint your own watch faces and browse exclusive drops.`,
    Slowgan_3: 'Or even applying to your watch in our mobile app is available!',
    AllRead: 'Read all',
    AllKeep: 'Keep it all',
    KeepCancel: 'Unarchive',
    KeepAlarm: 'Alarm storage',
    CopyItemLinkModal_Title: `The item's link\nhas been copied`,
    ConfirmDeleteMdal_Title:
      'Deleted faces cannot be restored.\n Do you want to proceed with deletion?',
    LogoutModal_Title: 'You have been logged out',
    RemoveContentModal_Title: 'Content has been removed',
    ApplyContentModal_Title: 'Content has been applied',
    AdminLoginFailModal_Title: 'You do not have administrator privileges',
    CancellSaleModal_Title: 'Do you want to cancel the sale?',
    CreateWatchModal_Title: 'Item creation complete!\n Check it on My Page and try selling it.',
    LanguageCodeInput: 'Enter language code',
    WritingList: 'List being edited',
    CurrentRealServerList: 'Current live server list',
    ListBeingEdited: 'List being edited',
    ServiceApply: 'Apply service',
    ServiceReservation: 'Service Reservation',
    TopMultiBanner: 'Top multi banner',
    MiddleMultiBanner: 'Middle band banner',
    BottomMultiBanner: 'Bottom band banner',
    Banner_NoTitle: 'Untitled',
    InputSearch: 'Please enter your search term',
    SearchClearButtonIncon: 'Delete search term button icon image',
    YearMonthDayUnit: 'Year.Month.Day',
    AdminReport_NoSearchIcon: 'Report details search icon',
    MetamaskWalletIcon: 'Metamask wallet icon',
    CheckIcon: 'Check icon',
    WalletIcon: 'Wallet icon',
    GoBackIcon: 'Back icon',
    MenuOpenIcon: 'Open menu icon',
    MenuCloseIcon: 'Close menu icon',
    NotificationIcon: 'Alarm icon',
    ArrowIcon: 'Select box arrow',
    NotificationedNotificationIcon: 'Alarm notification icon',
    CloseButton: 'Close Button',
    Locker: 'Locker',
    Inbox: 'Inbox',
    Newest: 'Newest',
    PriceHigh: 'High price',
    PriceLowest: 'Low price',
    AdminReport_Select: 'Select',
    AdminReport_HiddenKeep: 'Keep hidden',
    AdminReport_HiddenClear: 'Unhide',
    SearchLoading: 'Viewing watch face.',
    SearchNoRsCreate: 'There are no watch faces available to create.',
    SearchNoRsSale: 'There are no watch faces for sale.',
    SearchNoRsOwned: 'There are no watch faces purchased.',
    SearchNoRsHidden: 'There are no hidden watch faces.',
    SearchNoRsExplore: 'There are no registered watch faces.',
    Purchase_Loading: 'Purchase is in progress.',
    PurchaseProgress: 'Purchase is in progress.\nPlease wait a moment.',
    PurchaseComplete: 'Your purchase has been completed.',
    PurchaseFailed: 'Purchase failed.\nPlease try again.',
    SellFailed: 'Sale failed.\nPlease try again.',
    Confirm: 'Confirm',
    SignUpCelebrate_FirecrackerIcon: 'Welcome fireworks image',
    Detail_Loading: 'Loading details...',
    Enter_Email: 'Please enter your e-mail',
    Enter_Password: 'Please enter a password',
    Enter_NickName: 'Please enter your nickname',
    Enter_VerifyStatement: 'Please enter the authentication text',
    Enter_Code: 'Please enter the code',
    EmailResetTerms: 'Re-authentication is required when changing email.',
    Check_Email: `That email doesn't exist.`,
    Check_Code: 'The codes do not match.',
    Signup_CheckCertification: 'You have not authenticated.',
    Go_Back: 'Bacl',
    Signup_Agreeeterms: 'You did not agree to the terms and conditions.',
    Signup_Verificationcompleted: 'Verification completed',
    Signup_CheckVerification: 'Please check the authentication text again.',
    Signup_CheckPassword: 'Passwords do not match.',
    Signup_CheckDuplicateEmail: 'This is a duplicate email.',
    Signup_InvalidNickname: 'This nickname cannot be used.',
    Signup_Signup: 'Sign up failed. Please try again.',
    Signin_Signin: 'Login failed. Please try again.',
    UserPage_Loading: 'Loading user page information...',
    Explore_Loading: 'Loading watch face list...',
    Explore_NoSearch: 'Please search for the watch face information you are looking for.',
    Explore_NoSearchResult: 'There are no watch faces matching your search information.',
    AgreeTermsModal_TitleText: 'Agree to Terms of Use',
    AgreeTermsModal_TitleDetailText: 'Agree to Terms of Use and Privacy Policy',
    AgreeTermsModal_AllAgree: 'Totally agree',
    AgreeTermsModal_ButtonText: 'Sign up completed',
    AgreeTermsModal_AgreeUseTerm:
      'TIMEFLIK Terms of Use\n\nArticle 1 (Purpose)\n\nThese terms and conditions apply to all services provided by App Poster Co., Ltd. (hereinafter referred to as the ‘Company’) and the mobile application service(s) associated therewith ( "Timeflik',
    AgreeTermsModal_AgreePrivacyTerm:
      'Personal Information Processing Policy\n\nApp Poster Co., Ltd. (hereinafter referred to as “Company”) has established the following personal information processing policy to protect users’ personal information and to promptly and smoothly handle related complaints. Disclosed. ',
    HiddenItem: 'This face has been reported and hidden.',
    ReportReason: 'Reason',
    ContactUnhide: 'To unhide it, please contact customer service.',
    HiddenContent: 'This is hidden content.',
    Show_In_App: 'View in app',
    AdjustToWatch: 'Apply to watch',
    usingTestnet: 'Testnet is in use.',
    WorkInProcess: 'Work in progress...\nPlease wait a moment.',
    ConnectWallet_WalletNotConnected:
      'Your wallet is not connected. Please connect your wallet first.',
    networkError: 'A network error occurred. Please try again in a few minutes.',
    mintingError: 'Something went wrong when updloading the file.',
    chooseDate: 'Choose a date',
    reservationTime: 'Reservation time HH:mm',
    reservationList: 'Reservation list',
    proceedPurchase: 'Proceed with your purchase.',
    signingTimeout: 'Signature timed out. please try again.',
    approvalAll:
      'You need access to NFT tokens.\n Please grant full permission to continue selling.',
    mintNFT: 'Create an NFT token.',
    proceedSell: 'We are proceeding with the sale.',
    userCancel: 'You have canceled.',
    emailSent: 'Your email has been sent.',
    emailSentFail: 'Sending email failed.',
  },

  ja: {
    searchSidebar_PriceText: '価格',
    searchSidebar_Button: '適用',
    searchSidebar_CategoryText: 'カテゴリ',
    searchSidebar_OsText: 'OS',
    searchSidebar_CategoryList_analog: 'アナログ',
    searchSidebar_CategoryList_digital: 'デジタル',
    searchSidebar_CategoryList_digilog: 'デジログ',
    searchSidebar_CategoryList_informative: '参考情報',
    searchSidebar_OsList_android: 'Android',
    searchSidebar_OsList_ios: 'IOS',
    searchSidebar_FillterText: 'フィルター',
    Header_Explore: '探索',
    Header_Create: '作成',
    Header_MyDesign: '独自のデザイン',
    Header_MyApp: 'アプリをダウンロード',
    Header_WalletConnect: 'ウォレットに接続',
    Header_SingIn: 'サインイン',
    Header_TimeFlicText: 'TIMEFLIK アプリ',
    Header_BackText: '戻る',
    Header_AndroidDownload: 'Android ダウンロード',
    Header_IosDownload: 'iOS ダウンロード',
    Facefacelist_Loading: '作成できるウォッチフェイスのリストを読み込み中...',
    Facefacelist_SelectWatch: ' アイテムとして作成するウォッチフェイスを選択します。',
    Facefacelist_ShowInspectionCompleted: ' Flix Studio で検査された顔のみを表示できます。',
    Signin_PasswordResetText: 'パスワードをお忘れですか?',
    Signin_PasswordResetButton: 'パスワードをリセット',
    Signin_SocialLoginText: 'ソーシャル アカウントでログイン',
    Signin_SocialLogin: 'ソーシャルでログインする場合',
    And: 'そして',
    to: 'に',
    You_agree: '同意したものとみなします。',
    Signin_IshaveEmailText: `まだアカウントをお持ちですか?`,
    SignupButton: 'サインアップ',
    SigninButton: 'ログイン',
    PasswordRreset_InputEmailTerms: 'サインアップ時に指定したメール アドレスを入力してください。',
    PasswordRreset_PasswordRestText: 'パスワードをリセット',
    PasswordRreset_CheckEmail: 'メールを確認してください',
    PasswordRreset_SendResetPwdLink: `パスワード リセット リンクを送信しました。`,
    Signup_TitleText: 'サインアップ',
    Signup_NickNameTerm: '*ニックネーム (最低 2 文字)',
    Signup_NickNameTerm2: '20バイト以内で書いてください。',
    EmailTerm: ' *電子メール',
    Signup_EmailTerm2: ' 確認のために正しい情報を入力してください。',
    Signup_EmailSendButton: '送信',
    Signup_VerifystatementdButton: '検証',
    Signup_PasswordTerm_En: '英語',
    Signup_PasswordTerm_Num: '番号',
    Signup_PasswordTerm_Symbol: '特殊記号',
    Signup_PasswordTerm_safety: '安全性',
    AgreeUseTermCheckbox: '利用規約に同意する (必須)',
    AgreePrivacyTermCheckbox: 'プライバシー ポリシーに同意する (必須)',
    Singup_NickNameTerms: '*ニックネーム (最低 2 文字)',
    Signup_NickNameFocusTerms: '英語、韓国語、数字が利用可能',
    Singup_VerifyStatementTerms: '*検証テキスト',
    Singup_PasswordTerms: '*パスワード',
    ViewMoreButton: 'もっと見る',
    NextButton: '次へ',
    Next: '次へ',
    SignUpCelebrate_TitleText: 'サインアップが完了しました',
    SignUpCelebrate_Greetings: 'こんにちは! はじめまして',
    SignUpCelebrate_FindStarting: 'さて、自分のペースを見つけましょうか?',
    SignUpCelebrate_ForNftWhatchFace: 'NFT ウォッチフェイスを購入するには',
    SignUpCelebrate_ConnectWallet: 'ウォレットに接続する必要があります。',
    SignUpCelebrate_WalletConnectButton: 'ウォレットを接続',
    SignUpCelebrate_HomeButton: 'ホームに戻る',
    ConnectWallet_TitleText: 'ウォレット接続',
    ConnectWallet_MetamaskText: 'メタマスク',
    ConnectWallet_WalletCopyText: 'ウォレットアドレスがコピーされました',
    ConnectWallet_LinkCopyButton: 'リンクをコピー',
    ConnectWallet_ConnectClearButton: '切断',
    ConnectWallet_HomeButton: 'ホームに戻る',
    ConnectWallet_ConnectButton: '接続',
    ConnectWallet_MetamaskError: 'MetaMask のインストールが必要です。',
    ConnectWallet_ConnectError: 'ウォレットが接続されていません。',
    ConnectWallet_UnknownError: 'ウォレット接続に失敗しました。 もう一度試してください。',
    Footer_TimeFLIKPromotion:
      '世界中のユーザーが作成した何百万ものウォッチフェイスから、さまざまなデザイン、高品質のストラップ アクセサリーまで。 \nさあ、あなただけの時計を世界中のユーザーと共有しましょう。',
    Footer_TimeFlicExplain: '*TIMEFLIK(TM)は10年前から続くMR TIMEの新名称です。',
    Footer_TimeFlicAppDownloadNow:
      '今すぐ Time Flick アプリをダウンロードすると、さまざまな特典が待っています!',
    Footer_Contact_CompanyTitleText: 'Appposter.Inc.',
    Footer_Contact_CompanyBusinessNumberTitle: '事業者登録番号',
    Footer_Contact_CompanyBusinessNumber: '107-87-39753',
    Footer_Contact_CompanyReportNumberTitle: '電子商取引許可番号',
    Footer_Contact_CompanyReportNumber: '제2013-서울마포-1337호',
    Footer_Contact_CompanyCEOTitle: 'CEO',
    Footer_Contact_CompanyCEOName: 'Sunghyun Kyung',
    Footer_Contact_CompanyAddressTitle: '住所',
    Footer_Contact_CompanyAddress: ' 大韓民国ソウル市麻浦区城岩路189 DMCタワー12階',
    Footer_Contact_CompanyTelTitle: '電話',
    Footer_Contact_CompanyTelNumber: '1666 - 9015',
    Footer_Contact_CompanyFaxTitle: 'ファックス',
    Footer_Contact_CompanyFaxNumber: ' 02-538-0344',
    Footer_CopyrightExplain: 'Copyright 2023 Apposter.Inc. 無断転載を禁じます。',
    Terms_of_Use: '利用規約',
    Privacy_Policy: 'プライバシーポリシー',
    Contact_Us: 'お問い合わせ',
    App_Store: 'アプリストア',
    Google_Play: 'Googleプレイ',
    App_Download: 'アプリのダウンロード',
    Contact: '接触',
    Home_WatchfaceMarketplaceExplain:
      'クリエイティブな NFT デジタル資産を収集、売買するための最大の Watchface マーケットプレイス',
    CREATE_ITEM: 'アイテムの作成',
    New_Arrivals: '新着',
    Trending: 'トレンド',
    Home_MoreExplore: 'NFT ウォッチフェイスをさらに詳しく見る',
    Home_About_TIMEFLICK_NFTs: 'TIMEFLIK NFTについて',
    Home_AllowTimeFlicNft:
      'TIMEFLIK NFT ウォッチフェイスを使用すると、NFT を使用できますか? ブラブラブラ、スーパーカリフリガーリスティカスピアリドーシャス \n ブラブラブラ、スーパーカリフリガーリスティカスピアリドーシャス',
    HomeBannerTitle_IsFirstWatchspace: 'NFT ウォッチスペースを使用するのは初めてですか?',
    HomeBannerSubTitle_FrequentlyQuestions: 'FAQ',
    Go_To_FAQ: ' よくある質問へ行く',
    Create_Item: 'アイテムの作成',
    Cancel_Sale: '販売をキャンセルする',
    Details: '詳細',
    Item_Activity: 'アイテムアクティビティ',
    Event: 'イベント',
    Price: '価格',
    From: 'から',
    To: 'に',
    Date: '日付',
    Owned_By: 'が所有している',
    To_Cancel: '販売をキャンセルする',
    To_Delete: '消去',
    To_Sel: 'セール',
    Purchase: '購入',
    ShowApp: 'アプリで見る',
    HiddenFace: 'これは隠しコンテンツです。',
    RepotedHiddenFace: 'この顔は通報され隠蔽されています。',
    HowToCancelHidden: '再表示するには、カスタマー サービスにお問い合わせください。',
    CancleHiddenEmail: 'help@apposter.com',
    Item: 'アイテム',
    Reason: '理由',
    Blockchain: 'ブロックチェーン',
    ETH: 'ETH',
    Royalty: 'ロイヤルティ',
    Royalty_Terms: '10% の範囲内で小数点以下 2 桁まで入力できます。',
    Commission: 'コミッション',
    Commission_value: '2.5%',
    Total_Price: '合計価格',
    Unlockable_Content: 'ロック解除可能なコンテンツ',
    Page: 'ページ',
    Not_Found: ' 見つかりません',
    Error404_Detial: '要求したページが見つかりません',
    Go_back: '前のページに戻る',
    Go_Home: '家に帰ります',
    Search: '検索',
    WatchCount: 'ウォッチフェイス',
    TIMEFLIK_NFT_Marketplace: 'TIMEFLIK NFT マーケットプレイス',
    ConnectWallet: ' 管理者ウォレットに接続',
    Marketplace: 'マーケットプレイス',
    TMEFLK: 'T/MEFL/K',
    Logout: 'ログアウト',
    Banner_Precautions: '* アイテムを完全に保存した後、\n 新しいアイテムを作成してください',
    CommonBanner: '一般バナー',
    Cancel: 'キャンセル',
    Remove: '削除',
    Apply: '適用',
    Image: '画像',
    Arrival_URL: 'リンク先 URL',
    Title_Text: 'タイトルテキスト',
    Sub_Text: '小見出しテキスト',
    Button_Text: 'ボタンのテキスト',
    Minting_Loading: 'ミント作業が進行中です...',
    MultiBanner: 'マルチバナー',
    ReportedNoSearch: '登録されたレポートはありません。',
    Thums: 'サムネイル',
    Reported: '通報者',
    Reporter: '記者',
    ReportDate: 'レポート日',
    ReportDetailReason: '詳細な理由',
    ReportAction: 'アクション',
    ReportActionDate: 'アクションの日付',
    Report_Content: 'レポートの内容',
    Report_Reson: '報告の理由',
    ReportUnableCancel: '一度報告されると、レポートはキャンセルできません。',
    Report_Slander: '特定の人物に対する誹謗中傷',
    Report_Pornography: 'ポルノ',
    Report_Abuse: '不正行為',
    Report_DetailReasonTerms: '報告する詳しい理由を入力してください。',
    Report_Received: 'レポートを受信しました。',
    Report_Etc: 'その他',
    Report_Completed: 'レポートが完了しました',
    Reminder_Time: 'リマインダー時間',
    Slowgan_1: 'ウォッチフェイス NFT を見つけてください!',
    Slowgan_2: `TIMEFLIK NFT マーケットプレイスでは、独自のウォッチフェイスを作成したり、限定ドロップを閲覧したりできます。`,
    Slowgan_3: 'または、モバイル アプリで時計に適用することもできます!',
    AllRead: 'すべてを読む',
    AllKeep: 'すべてを保持',
    KeepCancel: 'アーカイブ解除',
    KeepAlarm: 'アラーム ストレージ',
    CopyItemLinkModal_Title: `アイテムのリンク\nコピーされました`,
    ConfirmDeleteMdal_Title: '削除された顔を復元することはできません。\n 削除を続行しますか?',
    LogoutModal_Title: 'ログアウトされました',
    RemoveContentModal_Title: 'コンテンツは削除されました',
    ApplyContentModal_Title: 'コンテンツが適用されました',
    AdminLoginFailModal_Title: '管理者権限がありません',
    CancellSaleModal_Title: '販売をキャンセルしますか?',
    CreateWatchModal_Title:
      'アイテムの作成が完了しました!\n マイページで確認して販売してみてください。',
    LanguageCodeInput: '言語コードを入力してください',
    WritingList: '編集中のリスト',
    CurrentRealServerList: '現在のライブサーバーリスト',
    ListBeingEdited: '編集中のリスト',
    ServiceApply: 'サービスを適用する',
    ServiceReservation: 'サービス予約',
    TopMultiBanner: 'トップマルチバナー',
    MiddleMultiBanner: 'ミドルバンドバナー',
    BottomMultiBanner: 'ボトムバンドバナー',
    Banner_NoTitle: '無題',
    InputSearch: '検索語を入力してください',
    SearchClearButtonIncon: '検索語ボタン アイコン画像を削除',
    YearMonthDayUnit: '年.月.日',
    AdminReport_NoSearchIcon: 'レポート詳細検索アイコン',
    MetamaskWalletIcon: 'メタマスク ウォレット アイコン',
    CheckIcon: 'チェックアイコン',
    WalletIcon: 'ウォレットアイコン',
    GoBackIcon: '戻るアイコン',
    MenuOpenIcon: 'メニューアイコンを開く',
    MenuCloseIcon: 'メニューアイコンを閉じる',
    NotificationIcon: 'アラームアイコン',
    ArrowIcon: 'ボックスの矢印を選択',
    NotificationedNotificationIcon: 'アラーム通知アイコン',
    CloseButton: '閉じるボタン',
    Locker: 'ロッカー',
    Inbox: '受信箱',
    Newest: '最新',
    PriceHigh: 'High price',
    PriceLowest: 'Low price',
    AdminReport_Select: '選択',
    AdminReport_HiddenKeep: '非表示のままにする',
    AdminReport_HiddenClear: '再表示',
    SearchLoading: 'ウォッチフェイスを表示しています。',
    SearchNoRsCreate: '作成できるウォッチフェイスがありません。',
    SearchNoRsSale: '販売中の文字盤はありません。',
    SearchNoRsOwned: '購入されたウォッチフェイスはありません。',
    SearchNoRsHidden: '非表示のウォッチフェイスはありません。',
    SearchNoRsExplore: '登録されたウォッチフェイスがありません。',
    Purchase_Loading: '購入が進行中です。',
    PurchaseProgress: '購入が進行中です。\nしばらくお待ちください。',
    PurchaseComplete: '購入は完了しました。',
    PurchaseFailed: '購入に失敗しました。\nもう一度お試しください。',
    SellFailed: '販売に失敗しました。\nもう一度お試しください。',
    Confirm: '確認',
    SignUpCelebrate_FirecrackerIcon: 'ウェルカム花火イメージ',
    Detail_Loading: '詳細を読み込んでいます...',
    Enter_Email: 'あなたのメールアドレスを入力してください',
    Enter_Password: 'パスワードを入力してください',
    Enter_NickName: 'ニックネームを入力してください',
    Enter_VerifyStatement: '認証テキストを入力してください',
    Enter_Code: 'コードを入力してください',
    EmailResetTerms: 'メールアドレスを変更する場合は再認証が必要です。',
    Check_Email: `そのメールは存在しません。`,
    Check_Code: 'コードが一致しません。',
    Signup_CheckCertification: '認証されていません。',
    Go_Back: 'Bacl',
    Signup_Agreeeterms: '利用規約に同意しませんでした。',
    Signup_Verificationcompleted: '検証が完了しました',
    Signup_CheckVerification: '認証テキストをもう一度確認してください。',
    Signup_CheckPassword: 'パスワードが一致しません。',
    Signup_CheckDuplicateEmail: 'これは重複したメールです。',
    Signup_InvalidNickname: 'このニックネームは使用できません。',
    Signup_Signup: 'サインアップに失敗しました。 もう一度お試しください。',
    Signin_Signin: 'ログインに失敗しました。 もう一度お試しください。',
    UserPage_Loading: 'ユーザー ページ情報をロード中...',
    Explore_Loading: 'ウォッチフェイスリストをロード中...',
    Explore_NoSearch: '探しているウォッチフェイス情報を検索してください。',
    Explore_NoSearchResult: '検索情報に一致するウォッチフェイスはありません。',
    AgreeTermsModal_TitleText: '利用規約に同意する',
    AgreeTermsModal_TitleDetailText: '利用規約とプライバシー ポリシーに同意する',
    AgreeTermsModal_AllAgree: '完全に同意します',
    AgreeTermsModal_ButtonText: 'サインアップが完了しました',
    AgreeTermsModal_AgreeUseTerm:
      '「TIMEFLIK利用規約」\n\n第1条（目的）\n\n本利用規約は、アプリポスター株式会社（以下「当社」といいます）が提供するすべてのサービスおよびモバイルアプリケーションサービスに適用されます。 ) それに関連する (「Timeflik」、',
    AgreeTermsModal_AgreePrivacyTerm:
      '「個人情報処理方針」\n\nアプリポスター株式会社（以下「当社」といいます）は、ユーザーの個人情報を保護し、これに伴う苦情に迅速かつ円滑に対応するため、以下の個人情報処理方針を定めます。 開示されました。',
    HiddenItem: 'この顔は報告され、非表示になりました。',
    ReportReason: '理由',
    ContactUnhide: '再表示するには、カスタマー サービスにお問い合わせください。',
    HiddenContent: 'これは非表示のコンテンツです。',
    Show_In_App: 'アプリで表示',
    AdjustToWatch: 'ウォッチに適用',
    usingTestnet: 'Testnetは使用中です',
    WorkInProcess: '作業中...\nしばらくお待ちください。',
    ConnectWallet_WalletNotConnected:
      'ウォレットが接続されていません。 まずウォレットを接続してください。',
    networkError: 'ネットワークエラーが発生しました。 数分後にもう一度試してください。',
    mintingError: 'ファイルのアップロード中に問題が発生しました。',
    chooseDate: '日付を選択してください',
    reservationTime: '予約時間 HH:mm',
    reservationList: '予約一覧',
    proceedPurchase: '購入を進めてください。',
    signingTimeout: '署名がタイムアウトしました。 もう一度試してください。',
    approvalAll:
      'NFT トークンにアクセスする必要があります。\n 販売を続行するには完全な許可を付与してください。',
    mintNFT: 'NFTトークンを作成します。',
    proceedSell: '販売を進めております。',
    userCancel: 'キャンセルされました。',
    emailSent: 'メールは送信されました。',
    emailSentFail: 'メールの送信に失敗しました。',
  },
});

export const lang = strings.getLanguage();
