import { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import './assets/css/nucleo-icons.css';
import './assets/css/nucleo-svg.css';
import {
  Explore,
  Home,
  SignIn,
  ConnectWallet,
  ItemDetails,
  SignUp,
  Admin,
  AdminHome,
  SignUpCelebrate,
  UserPage,
  Purchase,
  FaceFaceList,
  Error,
  MintingWrite,
  ResetPassword,
  FAQ,
  AuthPage,
  MessageListener,
} from './pages';

import './assets/css/output.css';
import './assets/css/index.css';

import Header from './Layout/Header';
import UserinfoContext from './context/UserinfoContext';
import { token } from './modules/API/UserAPI';
import { strings } from './utils/Localization/LocalizationStrings';
import { WalletConnectContextProvider } from './context/WalletConnectContext';
import { User } from './types';

const routerWithoutHeader = [
  'signin',
  'signup',
  '404',
  'admin',
  'adminhome',
  'connectwallet',
  'signupcelebrate',
  'resetpassword',
];

const routerWithSearchHeader = [
  'facefacelist',
  'explore',
  'userpage',
  'itemdetails',
  'reporteditems',
  'purchase',
  'mintingwrite',
];
const routerWithSearchoutHeader = [
  'userpage',
  'itemdetails',
  'reporteditems',
  'purchase',
  'mintingwrite',
];

const routes = [
  {
    path: '/',
    Component: Home,
    exact: true,
  },

  {
    path: '/userpage/:userid',
    Component: UserPage,
  },
  {
    path: '/explore',
    Component: Explore,
  },
  {
    path: '/signin',
    Component: SignIn,
  },
  {
    path: '/signup',
    Component: SignUp,
  },
  {
    path: '/admin',
    Component: Admin,
  },
  {
    path: '/adminhome',
    Component: AdminHome,
  },
  {
    path: '/signupcelebrate',
    Component: SignUpCelebrate,
  },
  {
    path: '/purchase/:id',
    Component: Purchase,
  },
  {
    path: '/mintingwrite/:id',
    Component: MintingWrite,
  },
  {
    path: '/facefacelist',
    Component: FaceFaceList,
  },
  {
    path: '/resetpassword',
    Component: ResetPassword,
  },
  {
    path: '/connectwallet',
    Component: ConnectWallet,
  },
  {
    path: '/itemdetails/:id',
    Component: ItemDetails,
  },
  {
    path: '/404',
    Component: Error,
  },
  {
    path: '/faq',
    Component: FAQ,
  },
  {
    path: '/message-listener',
    Component: MessageListener,
  },
];
const languages = ['en', 'ja', 'kr'];

const routeComponents = routes.map(({ path, Component }) =>
  languages.map((language) => (
    <Route exact path={`/${language}${path}`} component={Component} key={`/${language}${path}`} />
  ))
);

function App() {
  const [user, setUser] = useState<User | null>(null);

  const handleMessage = () => {
    const receiver = (document.getElementById('instant-token-receiver') as HTMLIFrameElement)
      .contentWindow;
    if (receiver === null) return;

    const instantToken = localStorage.getItem('X-Instant-Token');
    if (instantToken === null) return;

    setTimeout(() => {
      receiver.postMessage(
        {
          type: 'nft-marketplace',
          data: JSON.stringify({
            key: 'instantToken',
            value: instantToken,
          }),
        },
        '*'
      );
    }, 1000);
  };

  useEffect(() => {
    window.addEventListener('load', handleMessage);

    return () => {
      window.removeEventListener('load', handleMessage);
    };
  }, []);

  useEffect(() => {
    let tokenId = localStorage.getItem('X-Instant-Token');
    if (window.Appbridge?.getUserToken != null) {
      const appToken = window.Appbridge.getUserToken();
      tokenId = appToken;
      if (appToken != null) localStorage.setItem('X-Instant-Token', appToken);
    }
    if (tokenId == null) return;
    (async () => {
      if (tokenId !== null) {
        const userdata = await token({ tokenId });
        setUser(userdata);
      }
    })();
  }, []);

  const userState = useMemo(() => {
    return {
      user,
      setUser,
    };
  }, [user]);

  useEffect(() => {
    window.document.body.classList.add('g-sidenav-show');
    window.document.body.style.backgroundColor = '#f8f9fa !important';
  }, []);

  const { pathname } = useLocation();

  useEffect(() => {
    const lan = pathname.split('/')[1];
    strings.setLanguage(lan);
  }, [pathname]);

  return (
    <UserinfoContext.Provider value={userState}>
      <WalletConnectContextProvider>
        <div className="App">
          <div>
            <iframe
              title="instant-token-receiver"
              id="instant-token-receiver"
              src="https://localhost:3001/message-listener"
              style={{ display: 'none' }}
            />
          </div>
          <Helmet>
            <meta charSet="utf-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
            <title>NFT Marketplace</title>
            <link
              href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700"
              rel="stylesheet"
            />
            <link
              rel="stylesheet"
              href="https://use.fontawesome.com/releases/v5.2.0/css/all.css"
              integrity="sha384-hWVjflwFxL6sNzntih27bfxkr27PmbbK/iSvJ+a4+0owXq79v+lsFkW54bOGbiDQ"
              crossOrigin="anonymous"
            />
          </Helmet>

          {!routerWithoutHeader.includes(pathname.split('/').slice(-1).join()) && (
            <Header
              searchHeader={routerWithSearchHeader.includes(pathname.split('/')[2])}
              searchoutHeader={routerWithSearchoutHeader.includes(pathname.split('/')[2])}
            />
          )}

          <main className="max-w-[1200px] mx-auto">
            <Switch>
              {routeComponents}
              <Route exact path="/" component={Home} />
              <Route path="/admin" component={Admin} />
              <Route path="/adminHome" component={AdminHome} />
              <Route path="/faq" component={FAQ} />
              <Route path="/auth/:token" component={AuthPage} />
              <Route path="/404" component={Error} />
              <Redirect to={`/${strings.getLanguage()}/404`} />
            </Switch>
          </main>
        </div>
      </WalletConnectContextProvider>
    </UserinfoContext.Provider>
  );
}

export default App;
